import React, { Component } from 'react';
import {motion} from 'framer-motion';
import './part.css';

class Part extends Component {
    state = { animation:{
        animate:{},
        variants:{},
        transition:{},
        initial:{}
        }
    }
     

    render() { 

        if (this.props.animation){
            this.state.animation=this.props.animation;
        }

        return (
            <>
                <motion.section
                variants={this.state.animation.variants}
                initial={{opacity:0 ,scale:0.8 ,y:50}}
                transition={{duration:1}}
                animate={{opacity:1 ,scale:1,y:0}}
                className={"part-component part-page "+(this.props.className??'')} style={{maxWidth:this.props.width+'px' , backgroundColor:this.props.color??''}}> 
                    {this.props.children}
                </motion.section>
            </>
        );
    }
}
 
export default Part;

