import Cookies from 'js-cookie';
import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

class Logout extends Component {
    state = {  } 
    render() { 
        if(Cookies.get('token')!=undefined){
            Cookies.remove('token');
            Cookies.remove('role');
        }
        return (
            <>
                <Navigate to={'/'}/>
            </>
        );
    }
}
 
export default Logout;