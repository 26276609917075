import { useParams } from "react-router-dom";
import API from "../../../api";
import { useEffect, useState } from "react";
import StudentPanel from "../StudentPanel";
import PanelPart from "../../../components/mainComponents/mainPartPage/panelParts/panelPart";
import PageMessage from "../../../components/mainComponents/pageMessages/pageMessage/pageMessage";
import Butten from "../../../components/elementComponents/buttens/butten/butten";
import Form from "../../../components/elementComponents/forms/form/form";
import RadioInput from "../../../components/elementComponents/inputs/radioInputs/radioInput/radioInput";
import Option from "../../../components/appComponents/options/option";
import Timer from "../../../components/appComponents/timers/timer/timer";
import ListPanel2 from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';
import { color } from "framer-motion";

const StudentShowExam = (props) => {
    
    var api=new API();
    var params=useParams();

    const [data,setData]=useState({});
    const [pageMessage,setPageMessage]=useState(false);
    const [answers,setAnswers]=useState([]);


    useEffect(()=>{
        handleLoadInformation();
    },[]);

    return ( 
        <>
            <StudentPanel active='exam'>
                <PanelPart title={data.title}  description={data.description}>
                    {pageMessage==true ? 
                        <PageMessage onClose={handleClosePageMessage}>
                            <Form>
                                <h3>آیا از دادن آزمون اطمینان دارید؟</h3>
                                <br />
                                <Butten fontSize='14' onClick={handleStartExamButton}>برو به آزمون</Butten>
                                <Butten fontSize='14' onClick={handleClosePageMessage}>فقط مشاهده جزئیات آزمون</Butten>
                            </Form>
                        </PageMessage> : ''
                    }
                    {
                        data.take_exam=='N' || data.take_exam=='D' ?
                        <>
                            {
                                data.questions[0]!=undefined?
                                <>
                                    <Timer type='show' 
                                    start={data.questions[0]!=undefined ? 'on' : 'off'} 
                                    minutes={data.user_time_minutes} 
                                    secounds={data.user_time_secounds}
                                    onFinish={handleEndExamButton}
                                    />
                                    {
                                        data.questions.map((question,index)=>{
                                            var option_count=0;
                                            return(
                                            <>
                                                <Form width='500'>
                                                    <br />
                                                    <h2 style={{color:'white'}}>{question.title}</h2>
                                                    <br />
                                                    {
                                                        question.options.map(option=>{
                                                            option_count=option_count+1;
                                                            return(
                                                                <RadioInput
                                                                width='250'
                                                                type='radio' 
                                                                name={'optionsQuestion'+question.id} 
                                                                value={index+','+option_count} 
                                                                onChange={inputChangeAnswer}
                                                                >
                                                                    {option.title}
                                                                </RadioInput>
                                                            )
                                                        })
                                                    }
                                                </Form>
                                            </>
                                            )
                                        })
                                    }
                                    <Butten fontSize='16' height={'50'} width={'140'} onClick={handleEndExamButton}>پایان آزمون</Butten>
                                </>
                                :
                                <>
                                    <ListPanel2
                                    items={[
                                        ['آیدی',e=>e.id],
                                        ['تعداد سوالات',e=>e.tedad_question],
                                        ['وضعیت',e=>e.status=='on' ? 'فعال' : 'غیر فعال'],
                                        ['عنوان',e=>e.title],
                                        ['دوره',e=>e.period.title],
                                        ['نمره',
                                            (e)=>{
                                                return e.take_exam=='Y'?'اتمام آزمون':
                                                e.take_exam=='D'?'در حال آزمون':
                                                'شروع نشده';
                                            }
                                        ]
                                    ]}
                                    data={[data]}
                                    />
                                </>
                            }

                        </> 
                        :
                        <>
                            {
                                data.questions ? data.questions.map((question,index)=>{
                                    var option_count=0;
                                    return(
                                    <>
                                        <Form width='500'>
                                            <br />
                                            <h1 style={{color:'white'}}>{question.title}</h1>
                                            <br />
                                            {
                                                question.options.map((option,optionIndex)=>{
                                                    option_count=option_count+1;
                                                    var type='';
                                                    if(data.pivot.answers.split(',')[index]==optionIndex+1){
                                                        type=false;
                                                    }
                                                    if(option.is_answer==true){
                                                        type=true;
                                                    }
                                                    if(option.is_answer==true && data.pivot.answers.split(',')[index]==0){
                                                        type=0;
                                                    }
                                                    return(
                                                        <Option type={type}>
                                                            {option.title}
                                                        </Option>
                                                    )
                                                })
                                            }
                                        </Form>
                                        <br />
                                    </>
                                    )
                                })
                                : ''
                            }
                      
                        </> 
                    }
                </PanelPart>
            </StudentPanel>
        </>
    );

    function handleApiStartExam(data){
        setData(data.info);
    }

    function inputChangeAnswer(e){
        var ans =answers;
        ans[e.target.value.split(',')[0]]=e.target.value.split(',')[1];
        setAnswers(ans);
    }

    function handleLoadInformation(){
        api.send(
            props.addMessage,
            'get',
            'student/exam/show/'+params.id,
            handleMakeInformation
        );
    }
    function handleMakeInformation(data){
        setData(data.info);
        if(data.info.take_exam=='N'){
            setPageMessage(true);
        }
    }
    function handleStartExamButton(){
        handleClosePageMessage();
        api.send(
            props.addMessage,
            'get',
            'student/exam/start/'+params.id,
            handleApiStartExam
        );
        var ans=[];
        data.questions.forEach(element => {
            ans.push(0);
        });
        setAnswers(ans);
    }
    function handleClosePageMessage(){
        setPageMessage(false);
    }
    function handleEndExamButton(){
        if(answers==[]){
            data.questions.forEach(element => {
                ans.push(0);
            }); 
        }
        var ans=answers.toString();
        var data = new FormData();
        data.append('answers', ans);
        api.send(
            props.addMessage,
            'post',
            'student/exam/end/'+params.id,
            handleLoadInformation,
            data
        );
    }
}
 
export default StudentShowExam;