import React, { Component } from 'react';
import Part from '../../part';
import './dashboardMenuPart.css';
import { Link } from 'react-router-dom';
import Hr from '../../../../elementComponents/hrs/hr/hr';

class DashboardMenuPart extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <Part className="dashboardMenuPart-component dashboardMenuPart-page default-glass">
                    <Link to={'/'}><img src="/images/logo.png" alt="" /></Link>
                    <Hr/>
                    {this.props.children}
                </Part>
            </>
        );
    }
}
 
export default DashboardMenuPart;