import React, { Component } from 'react';
import './footer.css'
import Butten from '../../../elementComponents/buttens/butten/butten';
import Hr from '../../../elementComponents/hrs/hr/hr';

class Footer extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <footer className={'footer-component footer-page '+(this.props.className??'')}>

                    <div className='default-item-center-part'>
                        <div className='footer-interducePart-component'>
                            <h2>hamyar moallem</h2>
                            <br />
                            <h4>
                            در این سایت ما رابطه مجازی مدرس و دانشجو را بسیار ساده کرده این شما می توانید در هر بخش با خواندن توضیحات آن از امکانات ما مطلع شوید در اینجا چندین لینک برای رسیدن به قسمت های مختلف سایت را قرار داده ایم می توانید با آنها به خدمات ما نگاهی بیاندازید
                            </h4>
                            <br />
                            <Butten>log in</Butten>
                            <Butten>register</Butten>

                        </div>

                        <div className='footer-linksPart-component'>
                            <h2>
                                لینک های سایت
                            </h2>
                            <a href="">home</a>
                            <a href="">login</a>
                            <a href="">register</a>
                            <a href="">about me</a>
                            <a href="">blog</a>
                            <a href="">article</a>
                            <a href="">store</a>
                            <a href="">buying</a>
                            <a href="">dashboard</a>
                            <a href="">make class</a>
                            <a href="">make exam</a>
                            <a href="">make question</a>
                            <a href="">add student</a>
                            <a href="">see your profile</a>
                            <a href="">share files</a>
                            <a href="">share make a video</a>
                            <a href="">read instruction</a>
                            <a href="">read instruction</a>
                            <a href="">read instruction</a>
                            <p>end links</p>
                        </div>

                        <div>
                            <br />
                            <h4>
                                سایت همیار مدرس سایتی است برای سهولت رابطه بین مدرسان دوره ها و آموزشگاه های مختلف و دانش آموخته های این مدرسان در فضای مجازی . در واقع گزینه هایی که این سایت به کاربر هایش (مدرسان) میدهد ثبت کردن فایل و مطلب برای دانشجو ها و قرار دادن تکلیف برای آنها است . همچنین از سرویس هایی که قرار است به معلم ها داده شود می توان قابلیت گذاشتن آزمون برای دانش آموزان است. همچنین چت و پیامرسانی کوچک بین معلم و دانش آموزان.

                            </h4>
                        </div>
                    </div>

                    <Hr/>
                    
                    <h4>تمامی حقوق این سایت محفوظ و همچنین متعلق به محمد حسین ترقی می باشد</h4>

                </footer>
            </>
        );
    }
}

export default Footer;