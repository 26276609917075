import React, { Component } from 'react';
import TeacherPanel from '../TeacherPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import ListPanel2 from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';
import API from '../../../api';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import { Navigate } from 'react-router-dom';

class TeacherHomeworks extends Component {
    state = {  
        data:[],
        itemChosen:[],

        navigateDelete:false,
        navigateUpdate:false
    } 

    api=new API();

    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'teacher/drill/all',
            this.handleApiInformation,
        );
    }

    render() { 
        return (
            <>
                {this.state.navigateDelete==true ? <Navigate to='delete' state={{ items: this.state.itemChosen }}/> : ''}
                {this.state.navigateUpdate==true ? <Navigate to={'update/'+this.state.itemChosen[0].id} state={{ item: this.state.itemChosen[0] }}/> : ''}
                <TeacherPanel active='drill'>
                    <PanelPart title='تکالیف دوره ها' description='در این بخش شما می توانید لیستی از تکالیفی که برای دوره هایتان ثبت کرده اید مشاهده نمایید به همراه ویزگی هایی مانند دوره ای که برای آن تکلیف را ثبت کرده اید . شما با مشاهده هر کدام از تکلیف ها میزان فعالیت دانش آموزتان را مشاهده خواهید کرد'>
                        
                        <div className='default-item-center-part'>
                            <Butten onClick={this.handleUpdateButton}>
                                ویرایش تکلیف
                                <svg xmlns="http://www.w3.org/2000/svg" width={17.5} height={17.5} viewBox="0 0 24 24" fill="none" stroke="blue" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-edit">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                <path d="M16 5l3 3" />
                                </svg>
                            </Butten>
                            <Butten to='create'>
                                ثبت تکلیف
                                <svg xmlns="http://www.w3.org/2000/svg" width={17.5} height={17.5} viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-copy-plus">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path stroke="none" d="M0 0h24v24H0z" />
                                <path d="M7 9.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                <path d="M4.012 16.737a2 2 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                <path d="M11 14h6" />
                                <path d="M14 11v6" />
                                </svg>
                            </Butten>
                            <Butten onClick={this.handleDeleteButton}> 
                                حذف تکلیف
                                <svg xmlns="http://www.w3.org/2000/svg" width={17.5} height={17.5} viewBox="0 0 24 24" fill="none" stroke="green" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-trash">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M4 7l16 0" />
                                <path d="M10 11l0 6" />
                                <path d="M14 11l0 6" />
                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                </svg>
                            </Butten>
                        </div>
                        
                        <ListPanel2
                        data={this.state.data}
                        items={[
                            ['عنوان',e=>e.title],
                            ['توضیحات',e=>e.description],
                            ['وضعیت',e=>e.status],
                            ['دوره',e=>e.period.title]
                        ]}
                        onChoose={this.handleChooseItem}
                        />
                    </PanelPart>
                </TeacherPanel>
            </>
        );
    }

    handleApiInformation=(data)=>{
        this.setState({data:data.info});
    }

    handleChooseItem=(items)=>{
        this.setState({itemChosen:items});
    }
    handleDeleteButton=()=>{
        this.setState({navigateDelete:true});
    }
    handleUpdateButton=()=>{
        if(this.state.itemChosen.length != 1){
            this.props.addMessage(318,'باید حتما یک آیتم برای ویرایش انتخاب کنید');
        }
        else{
            this.setState({navigateUpdate:true});
        }
    }
}
 
export default TeacherHomeworks;