import React, { Component } from 'react';
import TeacherPanel from '../TeacherPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import ListPanel2 from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';
import API from '../../../api';

class TeacherStudents extends Component {
    state = {  
        data:[]
    } 

    api=new API();

    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'teacher/student/all',
            this.handleApiInformation
        );
    }

    render() { 
        return (
            <>
                <TeacherPanel active='student'>
                    <PanelPart title='دانشجو های دوره ها' description='دانشجو هایی را که در دوره های خصوصی و عمومی شما شرکت کرده اند را می توانید ببینید'>
                        <ListPanel2
                        buttons={['show']}
                        data={this.state.data}
                        items={[
                            ['آیدی',e=>e.id+'#'],
                            ['نام کاربری',e=>e.username],
                            ['نوع کاربر',e=>e.type]
                        ]}
                        />
                    </PanelPart>
                </TeacherPanel>
            </>
        );
    }

    handleApiInformation=(data)=>{
        this.setState({data:data.info});
    }
}
 
export default TeacherStudents;