import React, { Component } from 'react';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import StudentPanel from '../StudentPanel';
import ListPanel2 from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';
import API from '../../../api';
import PageMessage from '../../../components/mainComponents/pageMessages/pageMessage/pageMessage';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import Form from '../../../components/elementComponents/forms/form/form';
import { Navigate } from 'react-router-dom';

class StudentExam extends Component {
    state = {  
        data:[],
        navigateShow:false,
    } 

    api=new API();

    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'student/exam/all',
            this.handleApiInformation
        );
    }

    render() { 
        return (
            <>
            {this.state.navigateShow==true ? <Navigate to={'/panel/student/exam/'+this.state.itemChosen[0].id}/> : ''}

                <StudentPanel active='exam'>
                    <PanelPart title='آزمون ها' description='در این بخش می توانید آزمون هایی که توسط دوره های مختلف برای شما گذاشته شده را ببینید'>
                        
                        <ListPanel2
                        data={this.state.data}
                        items={[
                            ['آیدی',e=>e.id],
                            ['عنوان',e=>e.title],
                            ['توضیحات',e=>e.description],
                            ['دوره',e=>e.period.title],
                            ['وضعیت شما',
                                (e)=>{
                                    return e.take_exam=='Y'?'اتمام آزمون':
                                    e.take_exam=='D'?'در حال آزمون':
                                    'شروع نشده';
                                }
                            ]
                        ]}
                        buttons={['show']}
                        />
                    </PanelPart>
                </StudentPanel>
            </>
        );
    }

    handleApiInformation=(data)=>{
        this.setState({data:data.info});
    }


}
 
export default StudentExam;