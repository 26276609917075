import React, { Component } from 'react';
import StudentPanel from '../StudentPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import ListPanel2 from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';
import API from '../../../api';
import { Navigate } from 'react-router-dom';


class StudentPeriods extends Component {
    state = {  
        data:[],
        itemChosen:[],
        navigateDelete:false,
        navigateUpdate:false,
    } 

    api=new API();

    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'student/period/all',
            this.handleApiInformation
        );
    }


    render() { 
        return (
            <>
            
                {this.state.navigateDelete==true ? <Navigate to='delete' state={{ items: this.state.itemChosen }} /> : null}
                {this.state.navigateUpdate==true ? <Navigate to={'update/'+this.state.itemChosen[0].id} state={{ item: this.state.itemChosen[0] }} /> : null}
                <StudentPanel active='period'>
                    <PanelPart title="دوره ها" description='در این بخش می توانید دوره هایی را که در آن ثبت نام کرده اید را مشاهده بفرمایید و با کلیک بر آن اطلاعات هر دوره را مشاهده کنید '>

                      
                        <ListPanel2 
                        items={[
                            'id',
                            ['عنوان',e=>e.title],
                            ['توضیحات',e=>e.description],
                            ['فایل',e=>e.image_file,'file'],
                            ['نوع',e=>e.type == 'public' ? 'عمومی' : 'خصوصی'],
                            ['وضعیت',e=>e.status=='starting' ? 'در حال آغاز' : (e.status=='completing' ? 'در حال تکمیل' : 'تکمیل شده')]
                        ]} 
                        data={this.state.data} 
                        onChoose={this.handleChooseItem}
                        buttons={['show']}
                        />

                    </PanelPart>
                </StudentPanel>
            </>
        );
    }


    handleApiInformation=(data)=>{
        this.setState({data:data.info});
    }

    handleChooseItem=(items)=>{
        this.setState({itemChosen:items});
    }

}
 
export default StudentPeriods;