import React, { Component } from 'react';
import TeacherPanel from '../TeacherPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import Form from '../../../components/elementComponents/forms/form/form';
import SelectInput from '../../../components/elementComponents/inputs/selectInputs/selectInput/selectInput';
import Input from '../../../components/elementComponents/inputs/input/input';
import Textarea from '../../../components/elementComponents/inputs/textareas/textarea/textarea';
import API from '../../../api';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import { Navigate } from 'react-router-dom';

class TeacherHomeworkCreate extends Component {
    state = {  
        inputTitle:'',
        inputDescription:'',
        inputPeriod:'',
        inputStatus:'',
        navigate:false,

        periodItems:[],
    } 

    api=new API();

    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'teacher/period/all',
            this.handleApiPeriodInformation,
            {},
            ()=>{},
            false
        );
    }

    render() { 
        return (
            <>
                {this.state.navigate==true ? <Navigate to='/panel/teacher/drill'/> : ''}
                <TeacherPanel active='drill'>
                    <PanelPart title='ایجاد یک تکلیف' description='در این بخش شما می توانید به عنوان یک معلم برای دوره دلخواهتان با فیلد های مشخص شده تکلیف را برای دانش آموزانتان مشخص نمایید و با وضعیت های نختلف. اگر وضعیت خاموش را انتخاب نمایید تکلیف برای دانش آموز دیده نمی شود و اگر در حالت در حال انجام بگذارید یعنی دانشجو وقت برای انجام آن دارد و در غیر این صورت وقت آن تمام شده است'>
                        <Form>
                            <div className='default-item-center-part'>
                                <Input width='585' placeholder="عنوان را وارد نمایید" onChange={this.inputChangeTitle}>عنوان</Input>
                                <Textarea width='585' placeholder='توضیحات تکلیف را وارد کنید' onChange={this.inputChangeDescription}>توضیحات</Textarea>
                                <SelectInput width='280' options={['دوره مد نظر خود را وارد کنید',...this.state.periodItems]} onChange={this.inputChangePeriod}>دوره</SelectInput>
                                <SelectInput width='280' options={['وضعیت تکلیف را انتخاب کنید',['غیر فعال','off'],['فعال','doing']]} onChange={this.inputChangeStatus}>وضعیت</SelectInput>
                            </div>
                            <br />
                            <Butten onClick={this.handleCreateButton}>ایجاد</Butten>
                            <Butten to='/panel/teacher/drill'>بازگشت</Butten>
                        </Form>
                    </PanelPart>
                </TeacherPanel>
            </>
        );
    }

    handleApiPeriodInformation=(data)=>{
        var periods=[];
        data.info.forEach(element => {
            periods.push([element.title,element.id]);
        });
        this.setState({periodItems:periods});
    }

    inputChangeTitle=(e)=>{
        this.setState({inputTitle:e.target.value});
    }
    inputChangeDescription=(e)=>{
        this.setState({inputDescription:e.target.value});
    }
    inputChangePeriod=(e)=>{
        this.setState({inputPeriod:e.target.value});
    }
    inputChangeStatus=(e)=>{
        this.setState({inputStatus:e.target.value});
    }

    handleCreateButton=()=>{
        var data = new FormData();
        data.append('title', this.state.inputTitle);
        data.append('description', this.state.inputDescription);
        data.append('period', this.state.inputPeriod);
        data.append('status', this.state.inputStatus);
        
        this.api.send(
            this.props.addMessage,
            'post',
            'teacher/drill/create',
            ()=>{this.setState({navigate:true})},
            data
        );
    }
}
 
export default TeacherHomeworkCreate;