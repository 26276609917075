import React, { Component } from 'react';
import './textarea.css';

class Textarea extends Component {
    state = {  } 
    render() { 
        var label='';
        if(this.props.children){
            label=<><label htmlFor={this.props.id??''}>{this.props.children}</label><br/></>;
        }
        return (
            <>
            <div className={'textarea-component textarea-page '+(this.props.className??'')}>
                {label}
                <textarea 
                defaultValue={this.props.value??''}
                placeholder={this.props.placeholder??''} 
                id={this.props.id??''}
                onChange={this.props.onChange??(()=>{})} 
                rows={this.props.rows??7}
                style={{width:(this.props.width??'2000')+'px'}} />
            </div>
            </>
        );
    }
}
 
export default Textarea;