import React, { Component } from 'react';
import { BrowserRouter as Router , Routes , Route} from 'react-router-dom';
import App from '../pages/normalPanel/App';
import Login from '../pages/normalPanel/Login';
import Articles from '../pages/normalPanel/article/Articles';
import AboutUs from '../pages/normalPanel/AboutUs';
import ShowArticle from '../pages/normalPanel/article/ShowArticle';
import AdminPanel from '../pages/adminPanel/AdminPanel';
import AdminArticles from '../pages/adminPanel/Article/AdminArticles';
import Register from '../pages/normalPanel/Register';
import Logout from '../pages/normalPanel/Logout';
import MessageBox from './../components/mainComponents/fixeds/messageBoxs/messageBox/messageBox';
import AdminArticleCreate from '../pages/adminPanel/Article/AdminArticleCreate';
import TeacherDashboard from '../pages/teacherPanel/TeacherDashboard';
import TeacherPeriods from '../pages/teacherPanel/Period/TeacherPeriods';
import TeacherPeriodCreate from '../pages/teacherPanel/Period/TeacherPeriodCreate';
import TeacherPeriodDelete from '../pages/teacherPanel/Period/TeacherPeriodDelete';
import TeacherLessons from '../pages/teacherPanel/Lesson/TeacherLessons';
import TeacherLessonCreate from '../pages/teacherPanel/Lesson/TeacherLessonCreate';
import TeacherLessonDelete from '../pages/teacherPanel/Lesson/TeacherLessonDelete';
import TeacherPeriodUpdate from '../pages/teacherPanel/Period/TeacherPeriodUpdate';
import TeacherHomeworks from '../pages/teacherPanel/Homework/TeacherHomeworks';
import TeacherHomeworkCreate from '../pages/teacherPanel/Homework/TeacherHomeworkCreate';
import TeacherHomeworkDelete from '../pages/teacherPanel/Homework/TeacherHomeworkDelete';
import TeacherHomeworkUpdate from '../pages/teacherPanel/Homework/TeacherHomeworkUpdate';
import StudentDashboard from '../pages/studentPanel/StudentDashboard';
import StudentPeriods from '../pages/studentPanel/Period/StudentPeriods';
import Periods from '../pages/normalPanel/period/Periods';
import ShowPeriod from '../pages/normalPanel/period/ShowPeriod';
import StudentShowPeriod from '../pages/studentPanel/Period/StudentShowPeriod';
import StudentHomeworks from '../pages/studentPanel/Homework/StudentHomeworks';
import StudentLessons from '../pages/studentPanel/Lesson/StudentLessons';
import StudentShowLesson from '../pages/studentPanel/Lesson/StudentShowLesson';
import TeacherStudents from '../pages/teacherPanel/Student/TeacherStudents';
import TeacherPeriodShow from'../pages/teacherPanel/Period/TeacherPeriodShow';
import TeacherLessonUpdate from '../pages/teacherPanel/Lesson/TeacherLessonUpdate';
import TeacherStudentShow from '../pages/teacherPanel/Student/TeacherStudentShow';
import TeacherExams from '../pages/teacherPanel/Exam/TeacherExams';
import TeacherExamCreate from '../pages/teacherPanel/Exam/TeacherExamCreate';
import StudentExam from '../pages/studentPanel/Exam/StudentExam';
import StudentShowExam from '../pages/studentPanel/Exam/StudentShowExam';
import TeacherExamDelete from '../pages/teacherPanel/Exam/TeacherExamDelete';
import TeacherExamShow from '../pages/teacherPanel/Exam/TeacherExamShow';
import Chats from '../pages/chats/Chats';
import StudentShowHomework from '../pages/studentPanel/Homework/StudentShowHomework';

import BackGround from '../components/mainComponents/backGrounds/backGround-2/backGround';
import AdminArticleDelete from '../pages/adminPanel/Article/AdminArticleDelete';
import AdminUsers from '../pages/adminPanel/User/AdminUsers';
import TeacherGroups from '../pages/teacherPanel/Group/TeacherGroups';
import TeacherGroupUpdate from '../pages/teacherPanel/Group/TeacherGroupUpdate';
import TeacherGroupShow from '../pages/teacherPanel/Group/TeacherGroupShow';
import TeacherExamUpdate from '../pages/teacherPanel/Exam/TeacherExamUpdate';

class RoutesManager extends Component {

  state = { 
    messages:[]
  } 
  resizeFuncs = [];

  componentDidMount(){
    window.onresize = ()=>{
      this.resizeFuncs.forEach(element => {
        element();
      });
    }
  }

  render() { 
    return (
      <>
          {/* <BackGround/> */}
          <Router>
            <Routes>

              {/* normal panel */}
              <Route path="/" element={<App addResizeFunction={this.addResizeFunction} addMessage={this.addMessage}/>}/>
              <Route path="/login" element={<Login addMessage={this.addMessage}/>}/>
              <Route path="/logout" element={<Logout/>}/>
              <Route path="/register" element={<Register addMessage={this.addMessage}/>}/>
              <Route path="/article" element={<Articles addMessage={this.addMessage}/>}/>
              <Route path="/article/:id" exact element={<ShowArticle addResizeFunction={this.addResizeFunction} addMessage={this.addMessage}/>}/>
              <Route path="/period" element={<Periods addMessage={this.addMessage}/>}/>
              <Route path="/period/:id" exact element={<ShowPeriod addMessage={this.addMessage}/>}/>
              <Route path="/aboutUs" exact Component={AboutUs}/>



              {/* admin panel */}
              <Route path='/panel/admin' element={<AdminPanel addMessage={this.addMessage}/>}/>

              <Route path='/panel/admin/article' element={<AdminArticles addMessage={this.addMessage}/>}/>
              <Route path='/panel/admin/article/create' element={<AdminArticleCreate addMessage={this.addMessage}/>}/>
              <Route path='/panel/admin/article/delete' element={<AdminArticleDelete addMessage={this.addMessage}/>}/>
              
              <Route path='/panel/admin/user' element={<AdminUsers addMessage={this.addMessage}/>}/>


              {/* teacher panel */}
              <Route path='/panel/teacher' element={<TeacherDashboard addMessage={this.addMessage}/>}/>

              <Route path='/panel/teacher/period' element={<TeacherPeriods addMessage={this.addMessage}/>}/>
              <Route path='/panel/teacher/period/create' element={<TeacherPeriodCreate addMessage={this.addMessage}/>}/>
              <Route path='/panel/teacher/period/delete' element={<TeacherPeriodDelete addMessage={this.addMessage}/>}/>
              <Route path='/panel/teacher/period/update/:id' element={<TeacherPeriodUpdate addMessage={this.addMessage}/>}/>
              <Route path='/panel/teacher/period/:id' element={<TeacherPeriodShow addMessage={this.addMessage}/>}/>

              <Route path='panel/teacher/lesson' element={<TeacherLessons addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/lesson/create' element={<TeacherLessonCreate addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/lesson/delete' element={<TeacherLessonDelete addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/lesson/update/:id' element={<TeacherLessonUpdate addMessage={this.addMessage}/>}/>

              <Route path='panel/teacher/drill' element={<TeacherHomeworks addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/drill/create' element={<TeacherHomeworkCreate addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/drill/delete' element={<TeacherHomeworkDelete addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/drill/update/:id' element={<TeacherHomeworkUpdate addMessage={this.addMessage}/>}/>

              <Route path='panel/teacher/student' element={<TeacherStudents addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/student/:id' element={<TeacherStudentShow addMessage={this.addMessage}/>}/>

              <Route path='panel/teacher/exam' element={<TeacherExams addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/exam/create' element={<TeacherExamCreate addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/exam/update/:id' element={<TeacherExamUpdate addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/exam/delete' element={<TeacherExamDelete addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/exam/:id' element={<TeacherExamShow addMessage={this.addMessage}/>}/>
              
              <Route path='panel/teacher/group' element={<TeacherGroups addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/group/update/:id' element={<TeacherGroupUpdate addMessage={this.addMessage}/>}/>
              <Route path='panel/teacher/group/:id' element={<TeacherGroupShow addMessage={this.addMessage}/>}/>


              
              
              {/* student panel */}
              <Route path='/panel/student' element={<StudentDashboard addMessage={this.addMessage}/>}/>

              <Route path='/panel/student/period' element={<StudentPeriods addMessage={this.addMessage}/>}/>
              <Route path='/panel/student/period/:id' element={<StudentShowPeriod addMessage={this.addMessage}/>}/>
              
              <Route path='/panel/student/drill' element={<StudentHomeworks addMessage={this.addMessage}/>}/>
              <Route path='/panel/student/drill/:id' element={<StudentShowHomework addMessage={this.addMessage}/>}/>
              
              <Route path='/panel/student/lesson' element={<StudentLessons addMessage={this.addMessage}/>}/>
              <Route path='/panel/student/lesson/:id' element={<StudentShowLesson addMessage={this.addMessage}/>}/>

              <Route path='/panel/student/exam' element={<StudentExam addMessage={this.addMessage}/>}/>
              <Route path='/panel/student/exam/:id' element={<StudentShowExam addMessage={this.addMessage}/>}/>


              {/* chat */}
              <Route path='/chats' element={<Chats addMessage={this.addMessage}/>}/>

            </Routes>         
          </Router> 
          <MessageBox messages={this.state.messages}/>
      </>
    );
  }

  addMessage=(status,message)=>{
    var ran=Math.random();
    this.state.messages[this.state.messages.length] = [status,message,ran];
    this.setState({messages:this.state.messages});

    setTimeout(()=>{
      var array=this.state.messages; 
      array.splice(0,1);
      this.setState({messages:array});
    },5000);
  }
  addResizeFunction=(func)=>{
    this.resizeFuncs.push(func);
  }
  
}

 
export default RoutesManager;