import React, { Component } from 'react';
import Header from '../../../components/mainComponents/headers/header/header';
import Footer from '../../../components/mainComponents/footers/footer/footer';
import Part from '../../../components/mainComponents/mainPartPage/part';
import SearchBox from '../../../components/elementComponents/searchBoxs/searchBox/searchBox';
import Item from '../../../components/mainComponents/mainPartPage/showItemParts/items/item/item';
import ItemSuspended from '../../../components/elementComponents/ItemSuspendeds/itemSuspended/itemSuspended';
import InformationBar from '../../../components/elementComponents/informationBars-ShowItem/informationBar';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import Hr from '../../../components/elementComponents/hrs/hr/hr';
import API from '../../../api';

class Periods extends Component {
    state = {  
        items:[],
        inputSearch:'',
    } 

    api=new API();

    componentDidMount(){
        this.handleLoadInformation();
    }

    render() { 
        return (
            <>
                <Header/>
                <div className='main-value-page'>
                    <Part className=' default-glass' width='900'>
                        <div className="default-twoItem-part default-flex-right">
                            <div className='default-twoItem-part'>
                                <h1 className='default-margin'>دوره ها</h1>
                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="blue" stroke="blue" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-square">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                                </svg>
                            </div>
                            <SearchBox onChange={this.inputChangeSearch} onClick={this.handleSearchButton}/>
                        </div>
                        <br />
                        در بخش دوره های سایت می توانید به روز ترین دوره های عمومی و اختصاصی که توسط مدرسان درون سایت قرار دارند را ببینید و جست و حو کنید و در نهایت با مشاهده آنها عضو آنها شوید تا در جلسات و محتوا ها مشارکت داشته باشید لیست دوره ها در پایین دیده می شود 
                        <ItemSuspended color='#b9e2ff' bottom='-50' right='15'/>
                    </Part>


                    <Part width="1240" className="default-item-center-part ">
                        {
                            this.state.items.map((item)=>{
                                return(
                                    <Item>
                                        <img src={item.image_file} alt="" />
                                        <div>
                                            <h3>{item.title}</h3>
                                            <p>{item.description}</p>
                                            <Butten fontSize="14" to={'/period/'+item.id}>نمایش</Butten>
                                        </div>
                                    </Item>
                                );
                            })
                        }                        
                        <ItemSuspended left="-26" top="-17"/>
                    </Part>
                </div>

                <Footer/> 
            </>
        );
    }

    handleLoadInformation=(data={})=>{
        this.api.send(
            this.props.addMessage,
            'get',
            'period/all',
            this.handleApiInformation,
            data
        );
    }

    handleApiInformation=(data)=>{
        this.setState({items:data.info});
    }

    inputChangeSearch=(data)=>{
        this.setState({
            inputSearch:data.target.value
        });
    }

    handleSearchButton=()=>{
        this.handleLoadInformation({
            search:this.state.inputSearch
        });
    }
}
 
export default Periods;