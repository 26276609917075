import axios from "axios";
import Cookies from "js-cookie";

class API{

    send(
        addMessage,
        type,
        address,
        handle=()=>{},
        data={},
        notHandle=()=>{},
        message=true
    ){

        if(type=='get' && data!={}){
            address=address+'?'
            Object.keys(data).forEach((element,index) => {
                address=address+element+'='+(Object.values(data)[index])+'&';
                data={};
            });
        }
        axios(
            {
                method: type,
                url: 'https://back.hamyarmodarres.ir/api/v1/'+address,
                headers: { 
                    Authorization: 'Bearer '+(Cookies.get('token')??'')
                },
                data : data
            }

        ).then((response)=>{

            if(message==true){
                addMessage(response.status??200,response.data.message);
            }
            handle(response.data);
        
        }).catch((err)=>{
            if(err.response.status==401){
                addMessage(err.response.status,'هنوز وارد حساب خود نشده اید');
            }
            if(message==true){

                if(err.response.status==422){
                    for (const item in err.response.data.errors) {
                        addMessage(
                            err.response.status,
                            err.response.data.errors[item]
                        );
                    }
                }
                else if(err.response.status!=401){
                    addMessage(err.response.status,err.response.data.message);
                } 
                
            }
            notHandle(err);

        });

    }

}

export default API;