import React, { Component } from 'react';
import './timer.css';

class Timer extends Component {
    state = {  
        minutes:this.props.minutes??0,
        secounds:this.props.secounds??0
    }

    componentDidMount(){

        setInterval(()=>{
            if(!(this.state.minutes==0 && this.state.secounds==0)){
                
                if(this.props.start=='on'){
                    if(this.state.minutes==0 && this.state.secounds==1){
                        this.setState({secounds:0});
                        this.props.onFinish();
                    }
                    else if(this.state.secounds==0){
                        this.setState({minutes:this.state.minutes-1});
                        this.setState({secounds:59});
                    }
                    else{
                        this.setState({secounds:this.state.secounds-1});
                    }
                }
            }

        },1000);
        
    }

    render() { 
        return (
            <>
                {this.props.type=='show'?
                    <>
                        <div className='timer-component'>
                            <p style={{fontSize:(this.props.fontSize??20)}}>
                               {this.state.minutes+' : '+this.state.secounds}
                            </p>
                            </div>
                    </> 
                : ''}
            </>
        );
    }
}
 
export default Timer;