import React, { Component } from 'react';
import './option.css';
import { color } from 'framer-motion';

class Option extends Component {
    state = { 
        color:'#eafdff'
    } 
    componentDidMount(){
        switch (this.props.type){
            case false:
                this.setState({color:'#ff6c6c'});
                break;
            case true:
                this.setState({color:'#26ff7d'});
                break;
            case 0:
                this.setState({color:'yellow'})
        }
    }
    render() { 
        return (
            <>
                <div className={'option-component option-page '+this.props.className} style={{backgroundColor:this.state.color}}>
                    {this.props.children}
                </div>
            </>
        );
    }
}
 
export default Option;