import React, { Component } from 'react';
import TeacherPanel from '../TeacherPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import ListPanel2 from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';
import API from '../../../api';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import { Navigate } from 'react-router-dom';

class TeacherExams extends Component {
    state = {  
        data:[],
        itemChosen:[],

        navigateDelete:false,
        navigateUpdate:false
    } 

    api=new API();

    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'teacher/exam/all',
            this.handleApiInformation,
        );
    }

    render() { 
        return (
            <>
                {this.state.navigateDelete==true ? <Navigate to='delete' state={{ items: this.state.itemChosen }}/> : ''}
                {this.state.navigateUpdate==true ? <Navigate to={'update/'+this.state.itemChosen[0].id} state={{ item: this.state.itemChosen[0] }}/> : ''}
                <TeacherPanel active='exam'>
                    <PanelPart title='آزمون های دوره ها' description='در این قسمت آزمون هایی که برای دانشجو های خود قرار می دهید را مشاهده می نمایید'>
                        <Butten to='create'>ایجاد</Butten>
                        <Butten onClick={this.handleUpdateButton}>ویرایش</Butten>
                        <Butten onClick={this.handleDeleteButton}>حذف</Butten>
                        <ListPanel2
                        data={this.state.data}
                        items={[
                            ['عنوان',e=>e.title],
                            ['توضیحات',e=>e.description],
                            ['دوره',e=>e.period.title]
                        ]}
                        onChoose={this.handleChooseItem}
                        buttons={['show']}
                        />
                    </PanelPart>
                </TeacherPanel>
            </>
        );
    }

    handleApiInformation=(data)=>{
        this.setState({data:data.info});
    }

    handleChooseItem=(items)=>{
        this.setState({itemChosen:items});
    }
    handleDeleteButton=()=>{
        this.setState({navigateDelete:true});
    }
    handleUpdateButton=()=>{
        if(this.state.itemChosen.length != 1){
            this.props.addMessage(318,'باید حتما یک آیتم برای ویرایش انتخاب کنید');
        }
        else{
            this.setState({navigateUpdate:true});
        }
    }
}
 
export default TeacherExams;