import TeacherPanel from "../TeacherPanel";
import PanelPart from "../../../components/mainComponents/mainPartPage/panelParts/panelPart";
import Form from "../../../components/elementComponents/forms/form/form";
import Input from "../../../components/elementComponents/inputs/input/input";
import Textarea from "../../../components/elementComponents/inputs/textareas/textarea/textarea";
import Butten from "../../../components/elementComponents/buttens/butten/butten";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import API from "../../../api";
import { Navigate } from "react-router-dom";

const TeacherGroupUpdate = (props) => {

    const loc = useLocation();
    var params=useParams();
    var api = new API();

    const [item , setItem]=useState([]);
    const [navigate , setNavigate] = useState('');
    const [inputTitle , setInputTitle] = useState('');
    const [inputFile , setInputFile] = useState('');
    const [inputDescription , setInputDescription] = useState('');

    useEffect(()=>{
        api.send(
            props.addMessage,
            'get',
            'teacher/group/show/'+params.id,
            handleApiInformation,
        )
    },[]);

    return (  
        <>
            {navigate==true ? <Navigate to='/panel/teacher/group'/> : ''}
            <TeacherPanel active='group'>
                <PanelPart title='ویرایش گروه' description='در این بخش می توانید گفتگویی که در بین شما و دانشجویانی که در گروه چت تان حضور دارند را کنترل کنید'>
                    <Form>
                        <div className='default-item-center-part'>
                            <Input width='585' onChange={inputChangeTitle} placeholder="نام گروه را وارد کنید" value={item.name}>عنوان</Input>
                            <Input type='file' width='580' onChange={inputChangeFile}>عکس</Input>
                            <Textarea width='585' onChange={inputChangeDescription} placeholder='توضیحات گروه را وارد کنید' value={item.description}>توضیحات</Textarea>
                        
                        </div>
                        <br />
                        <Butten onClick={handleUpdateButton}>ویرایش</Butten>
                        <Butten to='/panel/teacher/drill'>بازگشت</Butten>
                    </Form>
                </PanelPart>
            </TeacherPanel>
        </>
    );

    function handleApiInformation(data){
        setItem(data.info);
        setInputTitle(data.info.name);
        setInputDescription(data.info.description);
    }

    function inputChangeTitle(e){
        setInputTitle(e.target.value);
    }
    function inputChangeFile(e){
        setInputFile(e.target.files[0]);
    }
    function inputChangeDescription(e){
        setInputDescription(e.target.value);
    }

    function handleUpdateButton(){
        var data = new FormData();
        data.append('name', inputTitle);
        data.append('description', inputDescription);
        data.append('img_file', inputFile);
        
        api.send(
            props.addMessage,
            'post',
            'teacher/group/update/'+params.id,
            ()=>{setNavigate(true)},
            data
        );
    }
}
 
export default TeacherGroupUpdate;