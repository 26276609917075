import React, { Component, createRef } from 'react';
import './slidingContent.css';

class SlidingContent extends Component {
    state = { } 
    contentRef = createRef();
    textRef = createRef();
    svgRef = createRef();
    render() { 
        return (
            <>
                <div className={'slidingContent-component slidingContent-page '+(this.props.className??'')}>   
                    
                    <div className='slidingContent-title-component'>
                        <h2>{this.props.title??'Title'}</h2>
                        <svg onClick={this.handleOpenClick} ref={this.svgRef} xmlns="http://www.w3.org/2000/svg" width={34} height={34} viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-caret-up">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M18 14l-6 -6l-6 6h12" />
                        </svg>
                    </div>

                    <div className={'slidingContent-content-component '+(this.state.slideStatus==true ? 'slidingContent-OpenContent-component' : '')} ref={this.contentRef}>
                        <div ref={this.textRef}>
                            {this.props.children??''}
                        </div>
                    </div>
                </div>
            </>
        );
    }

    handleOpenClick=()=>{
        if(this.contentRef.current.style.height == ''){
            this.contentRef.current.style.height = `${this.textRef.current.offsetHeight + 40}px`;
            this.svgRef.current.style.transform = `rotate(180deg)`;
        }
        else{
            console.log(this.contentRef.current.style.height);
            this.contentRef.current.style.height = '';   
            this.svgRef.current.style.transform = `rotate(0deg)`;
        }

    }
}
 
export default SlidingContent;