import React, { Component } from 'react';
import Part from '../../components/mainComponents/mainPartPage/part';
import Butten from '../../components/elementComponents/buttens/butten/butten';
import Input from '../../components/elementComponents/inputs/input/input';
import SelectInput from '../../components/elementComponents/inputs/selectInputs/selectInput/selectInput';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import API from '../../api';
import Form from '../../components/elementComponents/forms/form/form';
import ItemSuspended from '../../components/elementComponents/ItemSuspendeds/itemSuspended/itemSuspended';
import ItemSuspended3 from '../../components/elementComponents/ItemSuspendeds/itemSuspended-3/itemSuspended';

class Register extends Component {
    state = { 
        inputUsername:"",
        inputPassword:"",
        inputRole:"",
        navigate:false
    }
    api=new API();

    render() { 
        return (
            <>
                {this.state.navigate==true || Cookies.get('token')!=undefined ?  <Navigate to="/" /> :'' }
                <div className="default-fullWindow default-alignItem-center">
                    <Part className='default-two-side auth-section' width='1000'>
                        <div>
                            <img src="/images/sitePicture5.png" alt="" />
                        </div>
                        <div>
                            <Form className='default-shadow default-line-items' color='#adbbff'>
                                <br />
                                <h1>ثبت نام</h1>
                                <h3>از سایت کلی چیز یاد بگیر</h3>
                                <br />
                                <SelectInput width='300' height='53' options={['یک نقش را انتخاب کنید',['دانش جو','student'],['مدرس','teacher']]} onChange={this.inputChangeRole}/>
                                <Input width='310' height='57' placeholder='نام کاربری را وارد کنید' onChange={this.inputChangeUsername}/>
                                <Input type='password' width='310' height='57' placeholder='رمز عبور را وارد نمایید' onChange={this.inputChangePassword}/>
                                <Butten width='280' height='40' onClick={this.handleRegisterButton}>ثبت نام</Butten>
                                <Butten width='280' height='40' to='/'>برگشت</Butten>
                                <h4>dont you have any account??</h4>
                                <br />
                            </Form>
                        </div>
                        <ItemSuspended3 top='-30' left='5'/>
                        <ItemSuspended bottom='-5' right='15' color='white'/>
                    </Part>
                </div>
            </>
        );        
    }


    //inputs
    inputChangePassword=(e)=>{
        this.setState({inputPassword:e.target.value});
    }
    inputChangeUsername=(e)=>{
        this.setState({inputUsername:e.target.value});
    }
    inputChangeRole=(e)=>{
        this.setState({inputRole:e.target.value});
    }

    handleRegisterButton=()=>{
        this.api.send(
            this.props.addMessage,
            'post',
            'auth/register',
            this.handleApiReqisterButton,
            {username:this.state.inputUsername,password:this.state.inputPassword,type:this.state.inputRole}
            );
    }
    handleApiReqisterButton=(data)=>{
        Cookies.set('token',data.token, { expires: 1 });
        Cookies.set('role',this.state.inputRole, { expires: 1 });
        this.setState({navigate:true});
    }
}
 
export default Register;