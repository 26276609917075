import React, { Component } from 'react';
import './form.css';

class Form extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <div 
                className={'form-component form-page '+ (this.props.className??'')}
                style={{maxWidth:(this.props.width+'px' ?? 'auto') , backgroundColor:(this.props.color??'')}}
                >
                    {this.props.children}
                </div>
            </>
        );
    }
}
  
export default Form;