import React, { Component } from 'react';
import ChatPart from '../../components/mainComponents/mainPartPage/chatParts/chatPart/chatPart';
import API from '../../api';
import Fixed from '../../components/mainComponents/fixeds/fixed/fixed';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

class Chats extends Component {
    
    state = {  
        data_all:[],
        data_users:[],
        data_groups:[],
        data:[],
        messages:[],
        inputMessage:'',
        inputSearch:'',
        message_id:0,
        type:'myChats',
        navigate:false,
        your_type:'',
    } 
    api=new API();

    constructor(props){
        super(props);
        if(Cookies.get('role')!='teacher' && Cookies.get('role')!='student' && Cookies.get('role')!='admin'){
            this.state.navigate=true;
        }
    }
    componentDidMount(){
        this.handleLoadInformation();
    }

    render() { 
        return (
            <>
                {this.state.navigate == true ? <Navigate to='/'/> : ''}
                <Fixed className='default-alignItem-center' onClick={this.handleBackClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width={23} height={23} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-arrow-back-up">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 14l-4 -4l4 -4" />
                    <path d="M5 10h11a4 4 0 1 1 0 8h-1" />
                    </svg>
                </Fixed>
                <ChatPart
                    data={this.state.data}
                    data_all={this.state.data_all}
                    data_groups={this.state.data_groups}
                    data_users={this.state.data_users}
                    onSenderClick={this.handleSenderClick}
                    onChangeMessage={this.inputChangeMessage}
                    onChangeSearch={this.inputChangeSearch}
                    onSend={this.handleSend}
                    onSearch={this.handleSearch}
                    messages={this.state.messages}
                    your_type={this.state.your_type}
                />
            </>
        );
    }

    handleApiInformation=(data)=>{
        this.setState({
            data:data.info.data,
            data_all:data.info.data_all,
            data_groups:data.info.data_groups,
            data_users:data.info.data_users,
        });
    }
    handleApiInformationMessage=(data)=>{
        this.setState({
            messages:data.info.messages.reverse(),
            your_type:data.info.your_type
        });

    }

    inputChangeMessage=(e)=>{
        this.setState({inputMessage:e.target.value});
    }
    inputChangeSearch=(e)=>{
        this.setState({inputSearch:e.target.value});
    }

    handleLoadInformation=()=>{
        this.api.send(
            this.props.addMessage,
            'post',
            'message/all',
            this.handleApiInformation,
        );
        if(this.state.message_id != 0){
            this.handleSenderClick({id:this.state.message_id },this.state.type,false);
        }
    }
    handleSenderClick=(element,type,message=true)=>{
        this.api.send(
            this.props.addMessage,
            'post',
            'message/seen',
            this.handleApiInformationMessage,
            (type == 'myChats' ) ?{
                message_id : element.id,
            }:
            {
                user_id : element.id
            },
            ()=>{},
            message
        );

        this.setState({message_id:element.id , type:type});
    }
    handleSend=()=>{
        this.api.send(
            this.props.addMessage,
            'post',
            'message/create',
            ()=>{this.handleSenderClick({id:this.state.message_id },'myChats',false)},
            this.state.type == 'myChats' ?{
                message : this.state.inputMessage,
                message_id : this.state.message_id,
            }:
            {
                message:this.state.inputMessage,
                user_id:this.state.message_id
            }
        );
        this.api.send(
            this.props.addMessage,
            'post',
            'message/all',
            this.handleApiInformation,
            {},
            ()=>{},
            false
        );
    }
    handleSearch=()=>{
        this.api.send(
            this.props.addMessage,
            'post',
            'message/all',
            this.handleApiInformation,
            {search:this.state.inputSearch},
            ()=>{},
            false
        );
    }
    handleBackClick=()=>{
        this.setState({navigate:true});
    }
}
 
export default Chats;