import React, { Component } from 'react';
import './header.css';
import {motion} from 'framer-motion';
import Sidebar from '../../sidebars/sidebar/sidebar';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

class Header extends Component {
    state = { 
        animation:
        this.props.animation ?? {
        animate:{},
        variants:{},
        transition:{},
        initial:{}
        }
    }
 
    items=<ul>
        <li><Link to="/">خانه</Link></li>
        { 
        Cookies.get('token')==undefined ?
        <>
        <li><Link to="/register">ثبت نام</Link></li>
        <li><Link to="/login">ورود</Link></li>
        </> : <><li><Link to="/logout">خروج</Link></li> 
        <li><Link to={"/panel/"+Cookies.get('role')}>داشبورد</Link></li></> 
        }                           
        
        <li><Link to="/article">مقالات</Link></li>
        <li><Link to="/period">دوره ها</Link></li>
        <li><Link to="/aboutUs">درباره من</Link></li>
    </ul>;

    render() {
        
        return (
            <>
                 <motion.header

                   variants={this.state.animation.variants}
                   initial={this.state.animation.initial}
                   transition={this.state.animation.transition}
                   animate={this.state.animation.animate}
                   className={'header-page '+this.props.className??''}>

                    
                    <Link to={'/'}><img className="logo" src="/images/logo.png" alt=""/></Link>
                    <nav className="header-mainMenu-component">
                        {this.items}
                    </nav>
                    <div className="header-menuItems-component">
                        {/* <div className="header-menuItem-component"></div>
                        <div className="header-menuItem-component"></div> */}
                    </div>
                    <Sidebar className="header-sidebar-component">

                        {this.items}
                    </Sidebar>

                </motion.header>
            </>
        );
    }
}
 
export default Header;