import React, { Component } from 'react';
import Part from '../../part';
import './widgetPart.css';

class WidgetPart extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <Part className={'widgetPart2-component widgetPart2-page '+(this.props.className??'')}>
                    <ul className='widgetPart2-widgets-component'>
                        {this.props.children}
                    </ul>
                </Part>
            </>
        );
    }
}
 
export default WidgetPart;