import React, { Component, createRef } from 'react';
import Butten from './../../../elementComponents/buttens/butten/butten';
import './sidebar.css';

class Sidebar extends Component {
    state={
        sidebarStatus:'close-sidebar-component'
    }

    render() { 
        return (
        <div className={this.props.className??''}>

            <Butten className="sidebar-butten-open-component default-not-shadow" bgc="#00000000" onClick={this.handleOpenButton}>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-layout-list" width={34} height={34} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                <path d="M4 14m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v2a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
                </svg>
            </Butten>

            <div className={'sidebar-component sidebar-page '+this.state.sidebarStatus}>

                <Butten className="default-not-shadow default-not-bgc" onClick={this.handleCloseButton}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M18 6l-12 12" />
                    <path d="M6 6l12 12" />
                    </svg>
                </Butten>

                {this.props.children}
            </div>

        </div>
        );
    }



    handleOpenButton = () => {
        this.setState({sidebarStatus:'open-sidebar-component'});
    }
    handleCloseButton = () => {
        this.setState({sidebarStatus:'close-sidebar-component'});
    }
}
 
export default Sidebar;