import React, { Component } from 'react';
import Part from '../../components/mainComponents/mainPartPage/part';
import Butten from '../../components/elementComponents/buttens/butten/butten';
import Input from '../../components/elementComponents/inputs/input/input';
import Form from '../../components/elementComponents/forms/form/form';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';
import API from '../../api';
import ItemSuspended3 from '../../components/elementComponents/ItemSuspendeds/itemSuspended-3/itemSuspended';
import ItemSuspended from '../../components/elementComponents/ItemSuspendeds/itemSuspended/itemSuspended';

class Login extends Component {
    state = { 
        inputUsername:'',
        inputPassword:'',
        navigate:false
    } 

    api=new API();

    render() { 
        return (
            <>
                {this.state.navigate==true || Cookies.get('token')!=undefined ?  <Navigate to="/" /> :'' }
                <div className="default-fullWindow default-alignItem-center">
                    <Part className='default-two-side auth-section' width='1000'>
                        <div>
                            <img src="/images/sitePicture5.png" alt="" />
                        </div>
                        <div>
                            <Form className='default-shadow default-line-items' color='#adbbff'>
                                <br />
                                <h1>خوش برگشتی</h1>
                                <h3>ورود کن و از دوره ها استفاده کن</h3>
                                <br />
                                <Input width='318' height='57' placeholder=' نام کاربری را وارد کنید' onChange={this.inputChangeUsername}/>
                                <Input width='318' height='57' type='password' placeholder='رمز عبور را وارد کنید' onChange={this.inputChangePassword}/>
                                <Butten width='280' height='40' onClick={this.handleLoginButton}>ورود</Butten>
                                <Butten width='280' height='40' to='/'>برگشت</Butten>
                                <h4>dont you have any account??</h4>
                                <br />
                            </Form>
                        </div>
                        <ItemSuspended3 top='-30' left='5'/>
                        <ItemSuspended bottom='-5' right='15' color='white'/>
                    </Part>
                </div>
            </>
        );
    }


    inputChangeUsername=(e)=>{
        this.setState({inputUsername:e.target.value});
    }
    inputChangePassword=(e)=>{
        this.setState({inputPassword:e.target.value});
    }
    handleLoginButton=()=>{
        this.api.send(
            this.props.addMessage,
            'post',
            'auth/login',
            this.handleApiLoginButton,
            {username:this.state.inputUsername,password:this.state.inputPassword}
            );
    }
    handleApiLoginButton=(data)=>{
        Cookies.set('token',data.token, { expires: 1 });
        Cookies.set('role',data.type, { expires: 1 });
        this.setState({navigate:true});
    }

}
 
export default Login;