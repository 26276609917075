import React, { Component } from 'react';
import {animate , motion} from 'framer-motion';
import './butten.css';

import { Link } from 'react-router-dom';

class Butten extends Component {
    state = { animation:{
        animate:{},
        variants:{},
        transition:{},
        initial:{},
        }
    }

    render() { 

        if (this.props.animation){
            this.state.animation=this.props.animation;
        }

        
        return (
            <>
                <motion.button
                variants={this.state.animation.variants}
                initial={this.state.animation.initial}
                transition={this.state.animation.transition}
                animate={this.state.animation.animate}
                onClick={this.props.onClick} 
                className={"butten butten-page "+(this.props.className??'')}
                style={{height:(this.props.height??'')+'px' , width:(this.props.width??'')+'px' , fontSize:(this.props.fontSize??'')+'px' , backgroundColor:(this.props.bgc??'white')}}>

            <Link to={this.props.to} name={this.props.name??''}>
                    {this.props.children}
            </Link>


                </motion.button>
            </>
        );
    }

}
 
export default Butten;