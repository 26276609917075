import React, { Component } from 'react';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import TeacherPanel from '../TeacherPanel';
import Form from '../../../components/elementComponents/forms/form/form';
import Input from '../../../components/elementComponents/inputs/input/input';
import Textarea from '../../../components/elementComponents/inputs/textareas/textarea/textarea';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import API from '../../../api';
import { Navigate } from 'react-router-dom';
import SelectInput from '../../../components/elementComponents/inputs/selectInputs/selectInput/selectInput';

class TeacherExamCreate extends Component {
    state = {
        inputTitle:'',
        inputDescription:'',
        inputStatus:'',
        inputMinutes:'',
        inputPeriod:'',
        questions:[
            {
                title:'',
                answer:0,
                options:[
                    '',''
                ]
            },
        ],
        periodItems:[],
        navigate:false
    } 

    api=new API();

    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'teacher/period/all',
            this.handleApiPeriodInformation,
            {},
            ()=>{},
            false
        );
    }
    
    render() {
        var question_count=0;
        return (
            <>
            {this.state.navigate==true ? <Navigate to='/panel/teacher/exam'/> : ''}
                <TeacherPanel active='exam'>
                    <PanelPart title='ساخت یک آزمون' description='در این
                     قسمت برای دانش جویان یک دوره خود می توانید یک آزمون طراحی کنید'>
                        <Form>
                            <div className='default-item-center-part'>
                                <Input onChange={this.inputChangeTitle}  placeholder="عنوان آزمون را بنویسید" width='600'>عنوان</Input>
                                <Textarea width='600' onChange={this.inputChangeDescription}>توضیحات</Textarea>
                                <SelectInput width='600' options={['دوره مد نظر خود را انتخاب نمایید',...this.state.periodItems]} onChange={this.inputChangePeriod}>انتخاب دوره</SelectInput>
                                <SelectInput width='600' options={['لطفا وضعیت آزمون را مشخص کنید',['فعال','on'],['غیر فعال','off']]} onChange={this.inputChangeStatus}>انتخاب وضعیت</SelectInput>
                                <Input width='600' onChange={this.inputChangeMinutes}>دقیقه</Input>
                            </div>
                            <br />
                            {
                                this.state.questions.map(question=>{
                                    question_count=question_count+1;
                                    var option_count=0;
                                    return(
                                        <>
                                        <div className="default-item-center-part">
                                            <Input placeholder="جواب سوال را به صورت عدد تایپ کنید" onChange={this.InputChangeAnswer} name={question_count} width="450">جواب سوال</Input>
                                            <Input placeholder="عنوان سوال را بنویسید" onChange={this.InputChangeQuestion} name={question_count} width='450'>{' عنوان سوال '+question_count}</Input>
                                            {
                                                question.options.map(option=>{
                                                    option_count=option_count+1;
                                                    return(
                                                        <Input  placeholder="متن گزینه را تایپ کنید" onChange={this.InputChangeOption} name={question_count+','+option_count} width='450'>{'عنوان گزینه '+option_count}</Input>
                                                    );
                                                })
                                            }
                                        </div>
                                        <Butten fontSize='17' name={question_count} onClick={this.handleAddOptionButton}>اضافه کردن گزینه</Butten>
                                        <br />
                                        <br />
                                        </>
                                    );
                                })
                            }
                            <Butten fontSize='20' onClick={this.handleAddQuestionButton}>اضافه کردن سوال</Butten>
                            <Butten fontSize='20' onClick={this.handleCreateButton}>ایجاد</Butten>

                        </Form>
                    </PanelPart>
                </TeacherPanel>
            </>
        );
    }

    handleApiPeriodInformation=(data)=>{
        var periods=[];
        data.info.forEach(element => {
            periods.push([element.title,element.id]);
        });
        this.setState({periodItems:periods});
    }
    handleApiCreateButton=()=>{
        this.setState({navigate:true});
    }

    inputChangePeriod=(e)=>{
        this.setState({inputPeriod:e.target.value});
    }
    inputChangeStatus=(e)=>{
        this.setState({inputStatus:e.target.value});
    }
    inputChangeTitle=(e)=>{
        this.setState({inputTitle:e.target.value});
    }
    inputChangeDescription=(e)=>{
        this.setState({inputDescription:e.target.value});
    }
    inputChangeMinutes=(e)=>{
        this.setState({inputMinutes:e.target.value});
    }
    InputChangeAnswer=(e)=>{
        var questions=this.state.questions;
        questions[e.target.name-1].answer=e.target.value;
        this.setState({questions:questions});
    }
    InputChangeQuestion=(e)=>{
        var questions=this.state.questions;
        questions[e.target.name-1].title=e.target.value;
        this.setState({questions:questions});
    }
    InputChangeOption=(e)=>{
        var questions=this.state.questions;
        var question_count=e.target.name.split(",")[0];
        var option_count=e.target.name.split(",")[1];
        questions[question_count-1].options[option_count-1]=e.target.value;
        this.setState({questions:questions});
    }

    handleCreateButton=()=>{
        var questions=this.state.questions;
        var data = new FormData();
        data.append('title', this.state.inputTitle);
        data.append('description', this.state.inputDescription);
        data.append('period', this.state.inputPeriod);
        data.append('status', this.state.inputStatus);
        data.append('minutes', this.state.inputMinutes);

        var question_count=0;
        questions.forEach(question => {
            question_count=question_count+1;
            data.append('question'+question_count, question.title);
            data.append('answer'+question_count, question.answer);
            var option_count=0;
            question.options.forEach(option => {
                option_count=option_count+1;
                data.append('question'+question_count+'option'+option_count, option);
            });
        });

        this.api.send(
            this.props.addMessage,
            'post',
            'teacher/exam/create',
            this.handleApiCreateButton,
            data
        );
    }
    handleAddQuestionButton=()=>{
        var questions=this.state.questions;
        questions.push(
            {
                title:'',
                answer:0,
                options:[
                    '',''
                ]
            }
        );
        this.setState({questions:questions});
    }
    handleAddOptionButton=(e)=>{
        var questions=this.state.questions;
        questions[e.target.name-1].options.push('');
        this.setState({questions:questions});
    }
}
 
export default TeacherExamCreate;