import { useLocation } from "react-router-dom";
import API from "../../../api";
import { Navigate } from "react-router-dom";

const TeacherHomeworkDelete = (props) => {
    var loc=useLocation();
    var api=new API();

    var items='';
    loc.state.items.forEach(element => {
        items=items+element.id+',';
    });
    api.send(
        props.addMessage,
        'post',
        'teacher/drill/delete',
        ()=>{},
        {items}
    );
    return ( 
        <>
            <Navigate to='/panel/teacher/drill'/>
        </>
     );
}
 
export default TeacherHomeworkDelete;