import React, { Component } from 'react';
import TeacherPanel from '../TeacherPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import API from '../../../api';
import ListPanel2 from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import { Navigate } from 'react-router-dom';

class TeacherGroups extends Component {
    state = {  
        data:[],
        itemChosen:[],
        navigateUpdate:false
    } 
    api = new API();

    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'teacher/group/all',
            this.handleApiInformation
        );
    }

    render() { 
        return (
            <>
                {this.state.navigateUpdate==true ? <Navigate to={'update/'+this.state.itemChosen[0].id} state={{ item: this.state.itemChosen[0] }}/> : ''}

                <TeacherPanel active='group'>
                    <PanelPart title='گروه های درسی ' description='در این قسمت می توانید گروه هایی که در قسمت چت به واسطه دوره های شما ایجاد شده زا ببینید و کنترل کنترل کنید'>
                        
                    <div className='default-item-center-part'>
                            <Butten onClick={this.handleUpdateButton}>
                                ویرایش گفتگو
                                <svg xmlns="http://www.w3.org/2000/svg" width={17.5} height={17.5} viewBox="0 0 24 24" fill="none" stroke="blue" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-edit">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                <path d="M16 5l3 3" />
                                </svg>
                            </Butten>
                        </div>

                        <ListPanel2
                        onChoose={this.handleChooseItem}
                        buttons={['show']}
                        data={this.state.data}
                        items={[
                            ['نام گروه',e=>e.name??'بدون اطلاعات'],
                            ['توضیحات',e=>(e.description == '' ? 'بدون اطلاعات' : e.description)],
                            ['آیدی',e=>e.id + '#'],
                        ]}/>
                    </PanelPart>
                </TeacherPanel>
            </>
        );
    }


    handleApiInformation = (data)=>{
        this.setState({
            data:data.info
        });
    }

    handleChooseItem=(items)=>{
        this.setState({itemChosen:items});
    }

    handleUpdateButton=()=>{
        if(this.state.itemChosen.length != 1){
            this.props.addMessage(318,'باید حتما یک آیتم برای ویرایش انتخاب کنید');
        }
        else{
            this.setState({navigateUpdate:true});
        }
    }
}
 
export default TeacherGroups;