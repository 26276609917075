import React, { Component } from 'react';
import './itemSuspended.css'

class ItemSuspended4 extends Component {
    state = { 
        top:'',
        left:'',
        bottom:'',
        right:''
     } 
    render() { 

        if(this.props.top) { this.state.top=this.props.top };
        if(this.props.left) {this.state.left=this.props.left};
        if(this.props.bottom) {this.state.bottom=this.props.bottom};
        if(this.props.right) {this.state.right=this.props.right};
    

        return (
            <>
                <div
                className={'item-suspended4-component '+this.props.className??null} 
                style={{top:this.state.top+'px' ,
                    left:this.state.left+'px' ,
                    bottom:this.state.bottom+'px' ,
                    right:this.state.right+'px' ,
                }}>
                    <div className="item-suspended4-side-component"></div>
                    <div className="item-suspended4-side-component"></div>
                    <div className="item-suspended4-side-component"></div>
                    <div className="item-suspended4-side-component"></div>
                    <div className="item-suspended4-side-component"></div>
                    <div className="item-suspended4-side-component"></div>
                </div>
            </>
        );
    }
}
 
export default ItemSuspended4;