import React, { Component } from 'react';
import './messageBox.css';

class MessageBox extends Component {
    state = { }
    

    render() {
        return (
            <>
                <div className={'messageBox-component messageBox-page '+(this.props.className??'')}>

                    {this.props.messages.map(
                        (message) => {
                        if(message[0]=='200'){
                            var svg=<svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="icon icon-tabler icon-tabler-check" 
                            width={40} 
                            height={40} 
                            viewBox="0 0 24 24" 
                            strokeWidth={2} 
                            stroke="green" 
                            fill="none" 
                            strokeLinecap="round" 
                            strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 12l5 5l10 -10" />
                            </svg>
                        }
                        else{
                            var svg=<svg 
                            xmlns="http://www.w3.org/2000/svg" 
                            className="icon icon-tabler icon-tabler-alert-triangle" 
                            width={47} 
                            height={47} 
                            viewBox="0 0 24 24" 
                            strokeWidth={2} 
                            stroke="yellow" 
                            fill="none" 
                            strokeLinecap="round" 
                            strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 9v4" />
                                <path d="M10.363 3.591l-8.106 13.534a1.914 1.914 0 0 0 1.636 2.871h16.214a1.914 1.914 0 0 0 1.636 -2.87l-8.106 -13.536a1.914 1.914 0 0 0 -3.274 0z" />
                                <path d="M12 16h.01" />
                            </svg>
                        }
                        return(
                        <>
                            <div className={'messageBox-message-component messageBox-'+message[0]+'-component'}>
                                {svg}
                                <p>{message[1]}</p>
                            </div>
                        </>
                        );
                    })}
                </div>
            </>
        );
    }
}
 
export default MessageBox;