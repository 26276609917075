import TeacherPanel from '../TeacherPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import { useEffect, useState } from 'react';
import API from '../../../api';
import { Navigate, useParams } from 'react-router-dom';
import ListPanel2 from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';
import Hr from '../../../components/elementComponents/hrs/hr/hr';
import PageMessage from '../../../components/mainComponents/pageMessages/pageMessage/pageMessage';
import SelectInput from '../../../components/elementComponents/inputs/selectInputs/selectInput/selectInput';
import Butten from '../../../components/elementComponents/buttens/butten/butten';

const TeacherGroupShow = (props) => {

    const [item , setItem] = useState({});
    const [inputRole , setInputRole] = useState('');
    const [userElement , setUserElement] = useState({});
    const [users , setUsers] = useState([]);
    const [navigate,setNavigate]=useState(false);
    const [pageMessage,setPageMessage]=useState(false);

    var api = new API();
    var params = useParams();
    useEffect(()=>{
        handleLoadInformation();
    },[]);


    return (  
        <>
        {pageMessage==true 
            ? <PageMessage onClose={handlePageClose}>
                <div className="default-line-items">
                    <img src={userElement.profile} alt="" className='default-image'/>
                    <br />
                    <h3>{userElement.name??userElement.username}</h3>
                    <h5>{userElement.job??'بدون اطلاعات شغلی'}</h5>
                    <br />
                    <br />
                    <SelectInput onChange={inputChangeRole} height='70' width='207' value={userElement.pivot.role} options={['یک نقش را انتخاب کنید',['سازنده','superAdmin'],['ادمین','admin'],['عضو عادی','member'],['خواننده','reader']]}></SelectInput>
                    <Butten onClick={handleUserEditButton} height='44' width='140'>تایید نقش عضو</Butten>
                    <br />
                </div>
            </PageMessage> 
        : ''}
        {navigate==true ? <Navigate to='/panel/teacher/group'/> : ''}
            <TeacherPanel active='group'>
                <PanelPart title={item.name ? 'گروه '+item.name : ''} description={item.description}>
                    <br />
                    <Hr/>
                    <br />
                    <ListPanel2
                        buttons={['edit']}
                        onEdit={handleListEditClick}
                        items={[
                            ['آیدی',e=>e.id],
                            ['نام',e=>e.name],
                            ['نام کاربری',e=>e.username],
                            ['شغل',e=>e.job??'بدون اطلاعات'],
                            ['پروفایل',e=>e.profile,'file'],
                        ]}
                        data={users}
                    />
                </PanelPart>
            </TeacherPanel>
        </>
    );

    function handleLoadInformation(message=true){
        api.send(
            props.addMessage,
            'get',
            'teacher/group/show/'+params.id,
            handleApiInformation,
            {},
            ()=>{setNavigate(true)},
            message
        );
    }
    function handleApiInformation(data){
        setItem(data.info);
        setUsers(data.info.users);
    }

    function inputChangeRole(e){
        setInputRole(e.target.value);
    }

    function handleListEditClick(element){
        setPageMessage(true);
        setUserElement(element);
        setInputRole(element.pivot.role)
    }
    function handleUserEditButton(){
        api.send(
            props.addMessage,
            'post',
            'teacher/group/userUpdate/' + item.id,
            ()=>{
                handlePageClose();
                handleLoadInformation(false);
            },
            {
                user_id:userElement.id,
                role:inputRole
            }
        );
    }
    function handlePageClose(){
        setPageMessage(false);
    }
}
 
export default TeacherGroupShow;