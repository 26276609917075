import React, { Component } from 'react';
import Part from '../part';
import './panelPart.css';

class PanelPart extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <Part className={"panelPart-component panelPart-page default-glass "+(this.props.className??'')}>
                    <div className='panelPart-titlePart-component'>
                        <h2>{this.props.title??''}</h2>
                        <p>{this.props.description}</p>
                    </div>
                    {this.props.children}
                </Part>
            </>
        );
    }
}
 
export default PanelPart;