import React, { Component, useEffect, useState } from 'react';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import TeacherPanel from '../TeacherPanel';
import Form from '../../../components/elementComponents/forms/form/form';
import Input from '../../../components/elementComponents/inputs/input/input';
import Textarea from '../../../components/elementComponents/inputs/textareas/textarea/textarea';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import API from '../../../api';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import SelectInput from '../../../components/elementComponents/inputs/selectInputs/selectInput/selectInput';

const TeacherExamUpdate = (props) => {

    var api=new API();
    const loc = useLocation();
    var params = useParams();

    const [item,setItem] = useState(loc.state ? loc.state.item??{} : {});
    const [inputDescription,setInputDescription] = useState(item.description??'');
    const [inputStatus,setInputStatus] = useState(item.status??'');
    const [inputMinutes,setInputMinutes] = useState(item.time??'');
    const [inputTitle,setInputTitle] = useState(item.title??'');
    const [inputPeriod,setInputPeriod] = useState(item.period?item.period.title:'');
    const [questions,setQuestions] = useState([
        {
            title:'',
            answer:0,
            options:[
                '',''
            ]
        }
    ]);
    const [periodItems,setPeriodItems] = useState([]);
    const [navigate,setNavigate] = useState(false);
    

    useEffect(()=>{
        api.send(
            props.addMessage,
            'get',
            'teacher/period/all',
            handleApiPeriodInformation,
            {},
            ()=>{},
            false
        );
        var questions = [];
        item.questions.forEach(question => {
            var options = [];
            var answer = 1;
            question.options.forEach((option , index) => {
                options.push(option.title);
                if(option.is_answer==true){
                    answer=index+1;
                }
            });
            questions.push({
                title:question.title,
                options:options,
                answer:answer
            });
        });
        setQuestions(questions);

        
    },[]);

    var question_count=0;

        return (
            <>
            {navigate==true ? <Navigate to='/panel/teacher/exam'/> : ''}
                <TeacherPanel active='exam'>
                    <PanelPart title='ساخت یک آزمون' description='در این
                     قسمت برای دانش جویان یک دوره خود می توانید یک آزمون طراحی کنید'>
                        <Form>
                            <div className='default-item-center-part'>
                                <Input onChange={inputChangeTitle} value={inputTitle} placeholder="عنوان را وارد کنید" width='600'>عنوان</Input>
                                <Textarea width='600' value={inputDescription} onChange={inputChangeDescription}>توضیحات</Textarea>
                                <SelectInput width='600' options={['دوره مد نظر خود را انتخاب کنید',...periodItems]} onChange={inputChangePeriod}>انتخاب دوره</SelectInput>
                                <SelectInput width='600' value={inputStatus} options={['وضعیت آزمون را مشخص کنید',['فعال','on'],['غیر فعال','off']]} onChange={inputChangeStatus}>انتخاب وضعیت</SelectInput>
                                <Input width='600' value={inputMinutes} onChange={inputChangeMinutes}>دقیقه</Input>
                            </div>
                            <br />
                            {
                                questions.map(question=>{
                                    question_count=question_count+1;
                                    var option_count=0;
                                    return(
                                        <>
                                        <div className="default-item-center-part">
                                            <Input value={question.answer} placeholder="جواب سوال را به عدد وارد کنید" onChange={InputChangeAnswer} name={question_count} width="450">جواب سوال</Input>
                                            <Input value={question.title} placeholder="عنوان سوال را تایپ کنید" onChange={InputChangeQuestion} name={question_count} width='450'>{' عنوان سوال '+question_count}</Input>
                                            {
                                                question.options.map(option=>{
                                                    option_count=option_count+1;
                                                    return(
                                                        <Input value={option} placeholder="نوشته ی این گزینه را تایپ کنید" onChange={InputChangeOption} name={question_count+','+option_count} width='450'>{'عنوان گزینه '+option_count}</Input>
                                                    );
                                                })
                                            }
                                        </div>
                                        <Butten fontSize='17' name={question_count} onClick={handleAddOptionButton}>اضافه کردن گزینه</Butten>
                                        <br />
                                        <br />
                                        </>
                                    );
                                })
                            }
                            <Butten fontSize='20' onClick={handleAddQuestionButton}>اضافه کردن سوال</Butten>
                            <Butten fontSize='20' onClick={handleEditButton}>ویرایش</Butten>

                        </Form>
                    </PanelPart>
                </TeacherPanel>
            </>
        );

    function handleApiPeriodInformation(data){
        var periods=[];
        data.info.forEach(element => {
            periods.push([element.title,element.id]);
        });
        setPeriodItems(periods);
    }
    function handleApiEditButton(){
        setNavigate(true);
    }

    function inputChangePeriod(e){
        setInputPeriod(e.target.value);
    }
    function inputChangeStatus(e){
        setInputStatus(e.target.value);
    }
    function inputChangeTitle(e){
        setInputTitle(e.target.value);
    }
    function inputChangeDescription(e){
        setInputDescription(e.target.value);
    }
    function inputChangeMinutes(e){
        setInputMinutes(e.target.value);
    }
    function InputChangeAnswer(e){
        var questions=this.state.questions;
        questions[e.target.name-1].answer=e.target.value;
        setQuestions(questions);
    }
    function InputChangeQuestion(e){
        var questions=this.state.questions;
        questions[e.target.name-1].title=e.target.value;
        setQuestions(questions);
    }
    function InputChangeOption(e){
        var questions=this.state.questions;
        var question_count=e.target.name.split(",")[0];
        var option_count=e.target.name.split(",")[1];
        questions[question_count-1].options[option_count-1]=e.target.value;
        setQuestions(questions);
    }

    function handleEditButton(){
        var questions=questions;
        var data = new FormData();
        data.append('title', inputTitle);
        data.append('description', inputDescription);
        data.append('period', inputPeriod);
        data.append('status', inputStatus);
        data.append('minutes', inputMinutes);

        var question_count=0;
        questions.forEach(question => {
            question_count=question_count+1;
            data.append('question'+question_count, question.title);
            data.append('answer'+question_count, question.answer);
            var option_count=0;
            question.options.forEach(option => {
                option_count=option_count+1;
                data.append('question'+question_count+'option'+option_count, option);
            });
        });

        api.send(
            props.addMessage,
            'post',
            'teacher/exam/update/'+params.id,
            handleApiEditButton,
            data
        );
    }
    function handleAddQuestionButton(){
        var questions=questions;
        questions.push(
            {
                title:'',
                answer:0,
                options:[
                    '',''
                ]
            }
        );
        setQuestions(questions);
    }
    function handleAddOptionButton(e){
        var questions=questions;
        questions[e.target.name-1].options.push('');
        setQuestions(questions);
    }
}
 
export default TeacherExamUpdate;


