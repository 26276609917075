import { useParams } from "react-router-dom";
import API from "../../../api";
import { useEffect, useState } from "react";
import PanelPart from "../../../components/mainComponents/mainPartPage/panelParts/panelPart";
import TeacherPanel from "../TeacherPanel";
import ListPanel2 from "../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel";

const TeacherExamShow = (props) => {

    var api=new API();
    var params=useParams();

    const [data,setData]=useState([]);

    useEffect(() => {
        api.send(
            props.addMessage,
            'get',
            'teacher/exam/show/'+params.id,
            handleApiInformation
        );
    },[]);

    return (  
        <>
            <TeacherPanel active='exam'>
                <PanelPart title={'نمایش '+(data.title??'')} description={data.description + ' در بخش می توانید وضعیت دانشجو های خود را ببینید'}>

                        <ListPanel2
                            data={data.users??[]}
                            items={[
                                ['آیدی',e=>e.id],
                                ['نام کاربری',e=>e.username],
                                ['نمره',e=>e.pivot.point]
                            ]}
                        />
                </PanelPart>
            </TeacherPanel>
        </>
    );


    function handleApiInformation(data){
        setData(data.info);
    }
}
 
export default TeacherExamShow;