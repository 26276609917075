import React, { Component } from 'react';
import DashboardMenuPart from '../../components/mainComponents/mainPartPage/dashdoardMenuParts/dashboardMenuPart/dashboardMenuPart';
import { Link,Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';


class AdminPanel extends Component {
    state = { 
        dashoardClass:'dashboardMenuPart-chosenItem-component',
        sourceClass:'',
        productClass:'',
        articleClass:'',
        teacherClass:'',
        userClass:'',
        chatClass:'',
        navigate:false
    } 

    constructor(props){
        super(props);
        if(Cookies.get('role')!='admin'){
            this.state.navigate=true;
        }
        if(props.active!=undefined){
            this.state.dashoardClass='';
            switch (props.active) {
                case 'source':
                    this.state.sourceClass='dashboardMenuPart-chosenItem-component';
                    break;
                case 'article':
                    this.state.articleClass='dashboardMenuPart-chosenItem-component';
                    break;
                case 'user':
                    this.state.userClass='dashboardMenuPart-chosenItem-component';
                    break;
                case 'teacher':
                    this.state.teacherClass='dashboardMenuPart-chosenItem-component';
                    break;
                case 'chat':
                    this.state.chatClass='dashboardMenuPart-chosenItem-component';
                    break;
            }
        }
    }

    render() { 
        return (
            <>
                {this.state.navigate==true ? <Navigate to={'/login'}/> : ''}
                <div className='main-value-panels-page'>
                <DashboardMenuPart>
                    <ul>
                        <li className={this.state.dashoardClass}>
                            <Link to={'/panel/admin'}>داشبورد</Link>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                            <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                            </svg>
                        </li>
                        <li className={this.state.articleClass}>
                            <Link to={'/panel/admin/article'}>مقالات</Link>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-article" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 4m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                            <path d="M7 8h10" />
                            <path d="M7 12h10" />
                            <path d="M7 16h10" />
                            </svg>
                        </li>
                        <li className={this.state.userClass}>
                            <Link to={'/panel/admin/user'}>کاربران</Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-users">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 7m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0" />
                            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85" />
                            </svg>
                        </li>
                        <li className={this.state.chatClass}>
                            <Link to={'/chats'}>چت ها</Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-message">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M8 9h8" />
                            <path d="M8 13h6" />
                            <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                            </svg>
                        </li>
                    </ul>
                </DashboardMenuPart>

                {this.props.children}

                </div>
            </>
        );
    }
}
 
export default AdminPanel;