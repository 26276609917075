import StudentPanel from "../StudentPanel";
import PanelPart from "../../../components/mainComponents/mainPartPage/panelParts/panelPart";
import { Navigate, useParams } from "react-router-dom";
import API from "../../../api";
import { useEffect, useState } from "react";
import SingleItemPart from "../../../components/mainComponents/mainPartPage/singleItemParts/singleItemPart/singleItemPart";

const StudentShowLesson = (props) => {

    var params=useParams();
    var api=new API();

    const [data,setData]=useState({});
    const [navigate,setNavigate]=useState(false);

    useEffect( () => {
        api.send(
            props.addMessage,
            'get',
            'student/lesson/show/'+params.id,
            handleApiInformation,
            {},
            ()=>{setNavigate(true)}
        );
    } , []);

    return (  
        <>
        {navigate==true ? <Navigate to='/panel/student/lesson'/> : ''}
            <StudentPanel active='lesson'>
                <PanelPart title='Lesson Show!' description='Lorem ipsum dolor sit, amet consectetur adipisicing elit. Eligendi aut
                 repudiandae reprehenderit ipsa id at, ea architecto repellat consectetur ipsam velit sapiente ma
                 iores atque distinctio! Ratione, incidunt! Consequatur, iste laboriosam.'>
                    <SingleItemPart file={data.video??data.image_file} poster={data.image_file} className='default-color-dashboard singleItemPart-dashboard'>
                        <h2>{data.title}</h2>
                        <p>
                            {data.description}
                        </p>
                    </SingleItemPart>
                </PanelPart>
            </StudentPanel>
        </>
    );

    function handleApiInformation(data){
        setData(data.info);
    }

}
 
export default StudentShowLesson;