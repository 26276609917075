import React, { Component } from 'react';
import Input from '../../input/input';
import './radioInput.css';

class RadioInput extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <div className={'inputRadio-component inputRadio-page default-twoItem-part default-flex-right'+(this.props.className??'')}>
                    <p>{this.props.children}</p>
                    <Input  
                    height={this.props.height??'10'}
                    value={this.props.value??''}
                    name={this.props.name??''}
                    id={this.props.id??''}
                    onChange={this.props.onChange??(()=>{})} 
                    style={{width:(this.props.width??'')+'px'}} 
                    type='radio'/>
                </div>
            </>
        );
    }
}
 
export default RadioInput;