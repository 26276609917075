import PanelPart from "../../../components/mainComponents/mainPartPage/panelParts/panelPart";
import StudentPanel from "../StudentPanel";
import { Navigate, useParams } from "react-router-dom";
import API from "../../../api";
import { useEffect, useState } from "react";
import SingleItemPart from "../../../components/mainComponents/mainPartPage/singleItemParts/singleItemPart/singleItemPart";
import SlidingContent from "../../../components/elementComponents/slidingContents/slidingContent/slidingContent";
import ListPanel2 from "../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel";

const StudentShowPeriod = (props) => {

    var params=useParams();
    var api=new API();

    const [data,setData]=useState({});
    const [navigate,setNavigate]=useState(false);

    useEffect( () => {
        handleLoadInformation();
    } , []);

    return (  
        <>
            {navigate==true ? <Navigate to='/panel/student/period'/> : ''}
            <StudentPanel active='period'>
                <PanelPart title="نمایش دوره" description='در این قسمت می توانید دوره ای را که در آن ثبت نام کرده اید را مشاهده فرمایید'>
                        
                    <SingleItemPart img={data.image_file} className='default-color-dashboard singleItemPart-dashboard'>
                        <h2>{data.title}</h2>
                        <p>
                            {data.description}
                        </p>
                    </SingleItemPart>
                    
                    
                    <br />
                    <div className="default-line-items default-color-site">
                        <h1 className="default-margin">تکالیف برای دوره</h1>
                        <p>
                        در این بخش شما می توانید لیستی از تکالیفی که برای دوره هایتان ثبت کرده اید مشاهده نمایید به همراه ویزگی هایی مانند دوره ای که برای آن تکلیف را ثبت کرده اید . شما با مشاهده هر کدام از تکلیف ها میزان فعالیت دانش آموزتان را مشاهده خواهید کرد  
                        </p>
                    </div>
                   <ListPanel2
                        items={[
                            ['آیدی',e=>e.id],
                            ['عنوان',e=>e.title],
                            ['توضیحات',e=>e.description],
                            ['فایل',e=>e.image_file]
                        ]}
                        data={data.drills??[]}
                    />
                    <br />
                    <div className="default-line-items default-color-site">
                        <h1 className="default-margin">دروس</h1>
                        <p>
                        در این بخش شما می توانید لیستی از تکالیفی که برای دوره هایتان ثبت کرده اید مشاهده نمایید به همراه ویزگی هایی مانند دوره ای که برای آن تکلیف را ثبت کرده اید . شما با مشاهده هر کدام از تکلیف ها میزان فعالیت دانش آموزتان را مشاهده خواهید کرد  
                        </p>
                    </div>
                    
                     <ListPanel2
                        items={[
                            ['آیدی',e=>e.id],
                            ['عنوان',e=>e.title],
                            ['توضیحات',e=>e.description],
                            ['وضعیت',e=>e.status]
                        ]}
                        data={data.lessons??[]}
                    />
                </PanelPart>
            </StudentPanel>
        </>
    );


    function handleApiInformation(data){
        setData(data.info);
    }

    function handleLoadInformation(){
        api.send(
            props.addMessage,
            'get',
            'student/period/show/'+params.id,
            handleApiInformation,
            {},
            ()=>{setNavigate(true)}
        );
    }
    function handleDoneUnDoneDrillButten(element){
        api.send(
            props.addMessage,
            'get',
            'student/drill/done_failed/'+element.id,
            handleLoadInformation
        );
    }
}
 
export default StudentShowPeriod;