import React, { Component } from 'react';
import Header from '../../components/mainComponents/headers/header/header';
import Footer from '../../components/mainComponents/footers/footer/footer';
import Part from '../../components/mainComponents/mainPartPage/part';
import { Link } from 'react-router-dom';

class AboutUs extends Component {
    state = {  } 
  
    render() { 
        return (
            <>
                <Header/>

                <div className='main-value-page'>

                    <Part className='default-color-site default-glass' width="900">
                        <h1>درباره ما</h1>
                        <br />
                        <h3>
                            سایت همیار مدرس سایتی است برای سهولت رابطه بین مدرسان دوره ها و آموزشگاه های مختلف و دانش آموخته های این مدرسان در فضای مجازی . در واقع گزینه هایی که این سایت به کاربر هایش (مدرسان) میدهد ثبت کردن فایل و مطلب برای دانشجو ها و قرار دادن تکلیف برای آنها است . همچنین از سرویس هایی که قرار است به معلم ها داده شود می توان قابلیت گذاشتن آزمون برای دانش آموزان است. همچنین چت و پیامرسانی کوچک بین معلم و دانش آموزان.

                            این سایت یک وبلاگ در بحث آموزش نیز هست که با قرار دادن مقالاتی توسط ادمین سایت شما را از روش های تدرس و کار با سایت و ... مطلع می کند

                            <br/>
                            <br/>
                            راهنمای سایت برای مدرس
                            <br/>
                            ابتدا وارد قسمت ثبت نام میشویم و گزینه معلم را وارد می کنیم تا ثبت نام ما انجام شود سپس از بخش منو وارد بخش داشبورد خود می شویم 
                            در بخش منو داشبورد می توانیم گزینه ای با نام "دوره ها" مشاهده کنیم که وارد آن می شویم. درآن بخش جدولی خالی مشاهده می کنیم با چند دکمه در بالای آن. کار این بخش ساخت دوره  و ویرایش تنظیمات و حذف دوره هایی است که به عنوان معلم می توانید آنها را در لیست دوره های خود داشته باشید. همچنین شما می توانید در ساخت دوره مربوط به خود آن را خصوصی کنید و برای دوره خود رمزی خصوصی داشته باشید.  
                            در بخش منو داشبورد گزینه ای  دیگر با نام تکالیف می باشد که کارش قرار دادن تکالیف برای دانش آموزان مختلف یک داره است . وقتی که شما وارد این بخش می شوید لیستی از تکالیف که برای دوره ها گاشتید میبیند و می توانید آنها را ویرایش و بررسی نمایید
                            همچنین سایت ما بخشی برای محتوای دوره دارد که در آن محتوا های آپلودی تان را می توانید کنترل کنیم

                        </h3>
                    </Part>
                    <br />

                </div>

                <Footer/>
            </>
        );
    }
}
 
export default AboutUs;