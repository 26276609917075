import React, { Component } from 'react';
import TeacherPanel from '../TeacherPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import Form from '../../../components/elementComponents/forms/form/form';
import Input from '../../../components/elementComponents/inputs/input/input';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import { Navigate } from 'react-router-dom';
import API from '../../../api';
import Textarea from '../../../components/elementComponents/inputs/textareas/textarea/textarea';
import SelectInput from '../../../components/elementComponents/inputs/selectInputs/selectInput/selectInput';

class TeacherLessonCreate extends Component {
    state = {  
        inputTitle:'',
        inputDescription:'',
        inputImage:'',
        inputVideo:'',
        inputPeriod:'',
        navigate:false,

        periodItems:[]
    } 

    api=new API();

    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'teacher/period/all',
            this.handleApiPeriodInformation,
            {},
            ()=>{},
            false
        );
    }

    render() { 
        return (
            <>
                {this.state.navigate==true ? <Navigate to={'/panel/teacher/lesson'}/> : ''}
                <TeacherPanel active='lesson'>
                    <PanelPart title='ایجاد درس جدید' description='در این بخش می توانید با انتخاب دوره خود و فیلد های نوشته شده یک درس جدید را داشته باشید!!!'>
                        <Form>
                            <div className='default-item-center-part'>
                                <Input width='570' placeholder="عنوان را وارد نمایید" onChange={this.inputChangeTitle}>عنوان</Input>
                                <Textarea width='570' onChange={this.inputChangeDescription}>توضیحات</Textarea>
                                <Input type='file' width='570' onChange={this.inputChangeImage}>عکس</Input>
                                <Input type='file' width='570' onChange={this.inputChangeVideo}>ویدیو (غیر اجباری)</Input>
                                <SelectInput width='570' options={['دوره مد نظر را انتخاب نمایید',...this.state.periodItems]} onChange={this.inputChangePeriod}>انتخاب دوره</SelectInput>
                            </div>
                            <Butten onClick={this.handleCreateButton}>ایجاد</Butten>
                            <Butten to='/panel/teacher/period'>بازگشت</Butten>
                        </Form>
                    </PanelPart>
                </TeacherPanel>
            </>
        );
    }
    
    handleApiPeriodInformation=(data)=>{
        var periods=[];
        data.info.forEach(element => {
            periods.push([element.title,element.id]);
        });
        this.setState({periodItems:periods});
    }

    inputChangeTitle=(e)=>{
        this.setState({inputTitle:e.target.value});
    }
    inputChangeDescription=(e)=>{
        this.setState({inputDescription:e.target.value});
    }
    inputChangeImage=(e)=>{
        this.setState({inputImage:e.target.files[0]});
    }
    inputChangeVideo=(e)=>{
        this.setState({inputVideo:e.target.files[0]});
    }
    inputChangePeriod=(e)=>{
        this.setState({inputPeriod:e.target.value});
    }

    handleCreateButton=()=>{
        var data = new FormData();
        data.append('title', this.state.inputTitle);
        data.append('description', this.state.inputDescription);
        data.append('img_file', this.state.inputImage);
        data.append('video', this.state.inputVideo);
        data.append('period', this.state.inputPeriod);
        
        this.api.send(
            this.props.addMessage,
            'post',
            'teacher/lesson/create',
            ()=>{this.setState({navigate:true})},
            data
        );
    }
}
 
export default TeacherLessonCreate;