import StudentPanel from "../StudentPanel";
import PanelPart from "../../../components/mainComponents/mainPartPage/panelParts/panelPart";
import API from "../../../api";
import { useParams } from "react-router-dom";
import { useState,useEffect } from "react";
import { Navigate } from "react-router-dom";
import Part from "../../../components/mainComponents/mainPartPage/part";

const StudentShowHomework = (props) => {

    var params=useParams();
    var api=new API();

    const [data,setData]=useState({});
    const [navigate,setNavigate]=useState(false);

    useEffect( () => {
        api.send(
            props.addMessage,
            'get',
            'student/drill/show/'+params.id,
            handleApiInformation,
            {},
            ()=>{setNavigate(true)}
        );
    } , []);

    return (  
        <>
        {navigate==true ? <Navigate to='/panel/student/lesson'/> : ''}
            <StudentPanel active='drill'>
                <PanelPart title={data.title} description={data.description}>
                   <Part width='900' className='default-color-site'>
                        <h1>دانشجو عزیز</h1> 
                        <p>
                            شما باید تکالیف گفته شده را به سرعت انجام دهید سعی کنید متن بالا را کامل بخوانید و آنها را برای بهتر آموختن دروس خود انجام دهید
                            ضمنا اگر متوجه منظور دبیر نمی شوید یا نمی دانید چگونه تکالیف را انجام دهید حتما در قسمت چت ها از او بپرسید
                        </p>
                   </Part>
                   
                </PanelPart>
            </StudentPanel>
        </>
    );

    function handleApiInformation(data){
        setData(data.info);
    }
}
 
export default StudentShowHomework;