import React, { Component } from 'react';
import StudentPanel from '../StudentPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import ListPanel2 from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';
import API from '../../../api';
import Part from '../../../components/mainComponents/mainPartPage/part';

class StudentHomeworks extends Component {
    state = {  
        data:[]
    } 

    api=new API();
    
    componentDidMount(){
        this.handleLoadInformation();
    }

    render() { 
        return (
            <>
                <StudentPanel active='drill'>
                    <PanelPart title="تکالیف دوره ها" description='در این بخش می توانید تمام تکالیفی که در دوره های ثبت نام شده توسط شما وجود دارد را مشاهده فرمایید و اگر انجام داده این وضعیت شان را تغییر دهید '>
                        
                        <ListPanel2
                        data={this.state.data}
                        items={[
                            ['عنوان',e=>e.title],
                            ['توضیحات',e=>e.description],
                            ['وضعیت انجام',e=>e.pivot.doing_status],
                            ['وضعیت',e=>e.status],
                        ]}
                        buttons={[['انجام نشده/ شده',this.handleDoneUnDoneDrillButten],'show']}
                        />
                        
                    </PanelPart>
            </StudentPanel>
            </>
        );
    }

    handleApiInformation=(data)=>{
        this.setState({data:data.info});
    }

    handleLoadInformation=()=>{
        this.api.send(
            this.props.addMessage,
            'get',
            'student/drill/all',
            this.handleApiInformation,
        );
    }
    handleDoneUnDoneDrillButten=(element)=>{
        this.api.send(
            this.props.addMessage,
            'get',
            'student/drill/done_failed/'+element.id,
            this.handleLoadInformation
        );
        
    }
}
 
export default StudentHomeworks;