import React, { Component } from 'react';
import Butten from '../../../elementComponents/buttens/butten/butten';
import './fixed.css';

class Fixed extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <Butten onClick={this.props.onClick} className={'fixed-component fixed-page '+this.props.className??''}>
                    {this.props.children}
                </Butten>
            </>
        );
    }
}
 
export default Fixed;