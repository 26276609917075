import React, { Component } from 'react';
import './chatPart.css';
import Part from '../../part';
import Butten from '../../../../elementComponents/buttens/butten/butten';
import Input from '../../../../elementComponents/inputs/input/input';
import SearchBox from '../../../../elementComponents/searchBoxs/searchBox/searchBox';

class ChatPart extends Component {
    state = {  
        sendersPart:'data',
        type:'myChats'
    } 
    render() { 
        return (
            <>
                <Part className={'chatPart-component chatPart-page '+(this.props.className??'')}>
                    <div className='default-scrollbar-style chatPart-senders-component'>
                        <div className='chatPart-categories-component'>
                            <div className="chatPart-category-component" onClick={()=>{this.setState({sendersPart:'data' , type:'myChats'})}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-home">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                                </svg>
                            </div>
                            <div className="chatPart-category-component" onClick={()=>{this.setState({sendersPart:'data_groups' , type:'myChats'})}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} viewBox="0 0 24 24" fill="#9fb5ef" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-users-group">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M10 13a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                <path d="M8 21v-1a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v1" />
                                <path d="M15 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                <path d="M17 10h2a2 2 0 0 1 2 2v1" />
                                <path d="M5 5a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                <path d="M3 13v-1a2 2 0 0 1 2 -2h2" />
                                </svg>
                            </div>
                            <div className="chatPart-category-component" onClick={()=>{this.setState({sendersPart:'data_all' , type:'allChats'})}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} viewBox="0 0 24 24" fill="none" stroke=" white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-network">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M6 9a6 6 0 1 0 12 0a6 6 0 0 0 -12 0" />
                                <path d="M12 3c1.333 .333 2 2.333 2 6s-.667 5.667 -2 6" />
                                <path d="M12 3c-1.333 .333 -2 2.333 -2 6s.667 5.667 2 6" />
                                <path d="M6 9h12" />
                                <path d="M3 20h7" />
                                <path d="M14 20h7" />
                                <path d="M10 20a2 2 0 1 0 4 0a2 2 0 0 0 -4 0" />
                                <path d="M12 15v3" />
                                </svg>
                            </div>
                            <div className="chatPart-category-component" onClick={()=>{this.setState({sendersPart:'data_users' , type:'myChats'})}}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={34} height={34} viewBox="0 0 24 24"  fill="none" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-user">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                                </svg>
                            </div>
                        </div>
                        <div className="chatPart-senders-title-component">
                            <SearchBox 
                                width='230'
                                onChange={this.props.onChangeSearch}
                                onClick={this.props.onSearch}
                            />
                        </div>

                        {
                            this.state.sendersPart=='data'?
                                this.props.data.map(element=>
                                    <>
                                        <div className='chatPart-sender-component' onClick={()=>{this.props.onSenderClick(element,this.state.type)}}>
                                            <img src={element.profile??"/images/user.png"} alt="profile image"/>
                                            <div>
                                                <h3>{element.name ?? (element.type == 'group' ? 'گروه '+element.period.title :(element.type =='admin' ? 'ادمین' :element.username))}</h3>
                                                <h4>{element.type=='teacher' ? 'مدرس' : element.type=='student' ? 'دانشجو' : element.type=='group' ? 'گروه' : 'ادمین'}</h4>    
                                            </div>

                                        </div>
                                    </>
                                ):
                            this.state.sendersPart=='data_users' ?
                                this.props.data_users.map(element=>
                                    <>
                                        <div className='chatPart-sender-component' onClick={()=>{this.props.onSenderClick(element,this.state.type)}}>
                                            <img src={element.profile??"/images/user.png"} alt="profile image"/>
                                            <div>
                                                <h3>{element.name ?? (element.type == 'group' ? 'گروه '+element.period.title :(element.type == 'admin' ? 'ادمین' : element.username))}</h3>
                                                <h4>{element.type=='teacher' ? 'مدرس' : element.type=='student' ? 'دانشجو' : element.type=='group' ? 'گروه' : 'ادمین'}</h4>    
                                            </div>

                                        </div>
                                    </>
                                ):
                            this.state.sendersPart=='data_groups'?
                                this.props.data_groups.map(element=>
                                    <>
                                        <div className='chatPart-sender-component' onClick={()=>{this.props.onSenderClick(element,this.state.type)}}>
                                            <img src={element.profile??"/images/user.png"} alt="profile image"/>
                                            <div>
                                                <h3>{element.name ?? (element.type == 'group' ? 'گروه '+element.period.title :(element.name??element.username))}</h3>
                                                <h4>{element.type=='teacher' ? 'مدرس' : element.type=='student' ? 'دانشجو' : element.type=='group' ? 'گروه' : 'ادمین'}</h4>    
                                            </div>

                                        </div>
                                    </>
                                ):
                            this.state.sendersPart=='data_all'?
                                this.props.data_all.map(element=>
                                    <>
                                        <div className='chatPart-sender-component' onClick={()=>{this.props.onSenderClick(element,this.state.type)}}>
                                            <img src={element.profile??"/images/user.png"} alt="profile image"/>
                                            <div>
                                                <h3>{element.name ?? (element.type == 'group' ? 'گروه '+element.period.title :(element.type == 'admin' ? 'ادمین' : element.username))}</h3>
                                                <h4>{element.type=='teacher' ? 'مدرس' : element.type=='student' ? 'دانشجو' : element.type=='group' ? 'گروه' : 'ادمین'}</h4>    
                                            </div>

                                        </div>
                                    </>
                                ):''
                        }
                    </div>

                    <div className='default-scrollbar-style chatPart-messages-component'>
                        <div className="chatPart-messagesTitle-component">
                            <svg xmlns="http://www.w3.org/2000/svg" width={28} height={28} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-user">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                            </svg>
                            <p>پیام ها</p>
                        </div>
                        <div className="chatPart-messageBox-component">
                            {
                                this.props.messages.map(message=>
                                    <>
                                        <div className={'chatPart-message-component '+(message.type==this.props.your_type ? 'sender' : 'receiver')}>
                                            {message.user!=undefined ?
                                                <>
                                                    <img src={message.user.profile??'/images/user.png'} alt="userProfileInMessage" />
                                                    <h5>{message.user.name??message.user.username}</h5>
                                                </>
                                            :''}
                                            <p>
                                                {message.text}
                                            </p>
                                        </div>
                                    </>
                                )
                            }                            
                        </div>

                        
                        <div className='chatPart-sending-component'>
                            <Butten onClick={this.props.onSend}>
                                <svg xmlns="http://www.w3.org/2000/svg" width={29} height={29} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-send-2">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M4.698 4.034l16.302 7.966l-16.302 7.966a.503 .503 0 0 1 -.546 -.124a.555 .555 0 0 1 -.12 -.568l2.468 -7.274l-2.468 -7.274a.555 .555 0 0 1 .12 -.568a.503 .503 0 0 1 .546 -.124z" />
                                <path d="M6.5 12h14.5" />
                                </svg>
                            </Butten>
                            <Input width='400' placeholder='لطفا پیام خود را در 
                            اینجا بنویسید' onChange={this.props.onChangeMessage}></Input>
                        </div>
                    </div>
                </Part>
            </>
        );
    }


}
 
export default ChatPart;