import React, { Component } from 'react';
import './pageMessage.css';
import Butten from '../../../elementComponents/buttens/butten/butten';

class PageMessage extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <div className={'pageMessage-component pageMessage-page '+(this.props.className??'')}>
                    <div 
                    className='pageMessage-contain-component' 
                    style={{width:(this.props.width??'350')+'px' , height:(this.props.height??'')+'px'}}
                    >
                        <Butten className='default-not-shadow' onClick={this.props.onClose??(()=>{})}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M18 6l-12 12" />
                            <path d="M6 6l12 12" />
                            </svg>
                        </Butten>
                        {this.props.children}
                    </div>
                </div>
            </>
        );
    }
}
 
export default PageMessage;