import React, { Component } from 'react';
import './input.css';

class Input extends Component {
    state = {  } 
    render() { 
        var label='';
        if(this.props.children){
            label=<label htmlFor={this.props.id??''}>{this.props.children}</label>;
        }
        return (
            <>
            <div className={'Input-component Input-page '+(this.props.className??'')}>
                {label}
                <input 
                defaultValue={this.props.value??''}
                name={this.props.name??''}
                type={this.props.type??"text"} 
                placeholder={this.props.placeholder??''} 
                id={this.props.id??''}
                onChange={this.props.onChange??(()=>{})} 
                style={{width:(this.props.width??'')+'px' , height:(this.props.height??'')+'px'}}/>
            </div>
            </>
        );
    }
}
 
export default Input;