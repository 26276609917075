import React, { Component, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../../../components/mainComponents/headers/header/header';
import SingleItemPart from '../../../components/mainComponents/mainPartPage/singleItemParts/singleItemPart/singleItemPart';
import ItemSuspended from '../../../components/elementComponents/ItemSuspendeds/itemSuspended/itemSuspended';
import ItemSuspended3 from '../../../components/elementComponents/ItemSuspendeds/itemSuspended-3/itemSuspended';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import ShowItemPart3 from '../../../components/mainComponents/mainPartPage/showItemParts/showItemPart-3/showItemPart';
import Item from '../../../components/mainComponents/mainPartPage/showItemParts/items/item/item';
import Footer from '../../../components/mainComponents/footers/footer/footer';
import API from '../../../api';
import Hr from '../../../components/elementComponents/hrs/hr/hr';
import CommentPart from '../../../components/mainComponents/mainPartPage/commentParts/commentPart/commentPart';

const ShowArticle = (props) => {

    const [articleData,setArticleData]=useState([]);
    const [data,setData]=useState([]);
    const [comments,setComments]=useState([]);

    var api=new API();
    var params=useParams();

    useEffect( () => {
        handleLoadInformation();
        api.send(
            props.addMessage,
            'get',
            'main',
            (Adata)=>{setArticleData(Adata.articles)},
            {},
            ()=>{},
            false
        );
    } , []);

    return (
        <>
            <Header/>

            <div className='main-value-page'>
                <SingleItemPart file={data.image_file} className="singleItemPart">
                    <Hr/>
                    <br />
                    <h2>{data.title}</h2>
                    <br />
                    <p>{data.body}</p>
                    <br />
                    <Hr/>
                    <br />
                    <div className='default-line-items default-color-site'>
                        <p>میزان رضایت اعضای سایت از مقاله {data.likeCount} بوده </p>
                        <p className='default-margin'>از مقاله اش خوشت اومده یا نه ؟</p>
                        <div className='default-item-center-part'>
                            {
                                data.yourLike=='like'?
                                <svg onClick={handleArticleLike} xmlns="http://www.w3.org/2000/svg" width={28} height={28} viewBox="0 0 24 24" fill="currentColor" className="default-margin icon icon-tabler icons-tabler-filled icon-tabler-circle-check">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-1.293 5.953a1 1 0 0 0 -1.32 -.083l-.094 .083l-3.293 3.292l-1.293 -1.292l-.094 -.083a1 1 0 0 0 -1.403 1.403l.083 .094l2 2l.094 .083a1 1 0 0 0 1.226 0l.094 -.083l4 -4l.083 -.094a1 1 0 0 0 -.083 -1.32z" />
                                </svg>
                                :<svg onClick={handleArticleLike} xmlns="http://www.w3.org/2000/svg" width={28} height={28} viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="default-margin icon icon-tabler icons-tabler-outline icon-tabler-square-check">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z" />
                                <path d="M9 12l2 2l4 -4" />
                                </svg>
                            }
                            {
                                data.yourLike=='disLike'?
                                <svg onClick={handleArticleDisLike} xmlns="http://www.w3.org/2000/svg" width={28} height={28} viewBox="0 0 24 24" fill="currentColor" className="default-margin icon icon-tabler icons-tabler-filled icon-tabler-circle-x">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M17 3.34a10 10 0 1 1 -14.995 8.984l-.005 -.324l.005 -.324a10 10 0 0 1 14.995 -8.336zm-6.489 5.8a1 1 0 0 0 -1.218 1.567l1.292 1.293l-1.292 1.293l-.083 .094a1 1 0 0 0 1.497 1.32l1.293 -1.292l1.293 1.292l.094 .083a1 1 0 0 0 1.32 -1.497l-1.292 -1.293l1.292 -1.293l.083 -.094a1 1 0 0 0 -1.497 -1.32l-1.293 1.292l-1.293 -1.292l-.094 -.083z" />
                                </svg>
                                :<svg onClick={handleArticleDisLike} xmlns="http://www.w3.org/2000/svg" width={28} height={28} viewBox="0 0 24 24" fill="none" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="default-margin icon icon-tabler icons-tabler-outline icon-tabler-circle-x">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" />
                                <path d="M10 10l4 4m0 -4l-4 4" />
                                </svg>
                            }
                            
                            
                        </div>
                    </div>
                    <ItemSuspended bottom="-50" left="-60"/>
                    <ItemSuspended3 top="-80" left="-80"/>
                    <ItemSuspended3 bottom="-170" right="-80"/>
                </SingleItemPart>
                <br />
                <CommentPart comments={comments} onCreate={handleCrateComment}/>
                <br />
                <ShowItemPart3 addResize = {props.addResizeFunction}>
                    {articleData.map((article)=>(
                        <Item>
                            <img src={article.image_file} alt="" />
                            <div>
                                <h3>{article.title}</h3>
                                <p>{article.body}</p>
                                <Butten to={'/article/'+article.id} fontSize='14'>نمایش</Butten>
                            </div>
                        </Item>
                    ))}
                </ShowItemPart3>
                <br />
            </div>
            <Footer/>
        </>
    );

    function handleLoadInformation(message=true){
        api.send(
            props.addMessage,
            'get',
            'article/show/'+params.id,
            handleApiInformation,
            {},
            ()=>{},
            message
        );
    }
    function handleApiInformation(data){
        setData(data.info);
        setComments(data.info.comments);
    }

    function handleCrateComment(data){
        api.send(
            props.addMessage,
            'post',
            'comment/create',
            ()=>(handleLoadInformation(false)),
            {
                comment:data,
                commentable:'Article',
                commentable_id:params.id
            }
        );
    }

    function handleArticleLike(){
        api.send(
            props.addMessage,
            'post',
            'like/create',
            handleLoadInformation,
            {
                type:'like',
                likeable:'Article',
                likeable_id:data.id
            },
            ()=>{},
            false
        );
    }

    function handleArticleDisLike(){
        api.send(
            props.addMessage,
            'post',
            'like/create',
            handleLoadInformation,
            {
                type:'disLike',
                likeable:'Article',
                likeable_id:data.id
            },
            ()=>{},
            false
        );
    }
}
 
export default ShowArticle;

