import React, { Component } from 'react';
import API from '../../../api';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
 
const TeacherLessonDelete=(props)=>{

    var loc=useLocation();
    var api=new API();

    useEffect(() => {
        var items='';
        loc.state.items.forEach(element => {
            items=items+element.id+',';
        });
        api.send(
            props.addMessage,
            'post',
            'teacher/lesson/delete',
            ()=>{},
            {items}
        );
    }, []);

    return ( 
        <>
           <Navigate to='/panel/teacher/lesson'/>
        </>
     );
}

export default TeacherLessonDelete;

