import React, { Component } from 'react';
import AdminPanel from '../AdminPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import ListPanel2 from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';
import API from '../../../api';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import { Navigate } from 'react-router-dom';
import SearchBox from '../../../components/elementComponents/searchBoxs/searchBox/searchBox';
import Part from '../../../components/mainComponents/mainPartPage/part';

class AdminArticles extends Component {
    state = { 
        data:[],
        itemChosen:[],
        inputSearch:''
    } 

    api=new API();

    componentDidMount(){
        this.handleLoadInformation();
    }

    render() { 
        return (
            <>
                {this.state.navigateDelete==true ? <Navigate to='delete' state={{ items: this.state.itemChosen }} /> : null}
                <AdminPanel active="article">
                    <PanelPart title="Articles Yeah" 
                    description="Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                     Perspiciatis dolor reprehenderit cupiditate ipsum voluptatem fugiat? Voluptates minus officiis dolor at
                      necessitatibus. Ex, est. Iste assumenda nobis fugit illo officiis! Animi!">
                        <Part className='default-twoItem-part default-not-margin-padding' width='900'>
                            <SearchBox onChange={this.inputChangeSearch} onClick={this.handleSearchButton}/>
                            <div className='default-alignItem-center'>
                                <Butten to='create'>
                                    ثبت مقاله
                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-copy-plus">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <path d="M7 9.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                    <path d="M4.012 16.737a2 2 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                    <path d="M11 14h6" />
                                    <path d="M14 11v6" />
                                    </svg>
                                </Butten>
                                <Butten onClick={this.handleDeleteButton}> 
                                    حذف مقاله
                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="green" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-trash">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M4 7l16 0" />
                                    <path d="M10 11l0 6" />
                                    <path d="M14 11l0 6" />
                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                    </svg>
                                </Butten>
                            </div>
                        </Part>
                        <ListPanel2 
                        items={[
                            ['عنوان',e=>e.title],
                            ['متن مقاله',e=>e.body],
                            ['آیدی',e=>e.id+'#'],
                        ]} 
                        data={this.state.data} 
                        onChoose={this.handleChooseItem}/>
                    </PanelPart>
                </AdminPanel>
            </>
        );
    }

    handleApiInformation=(data)=>{
        this.state.data=data.info;
    }
    handleLoadInformation=(data={})=>{
        this.api.send(
            this.props.addMessage,
            'get',
            'admin/article/all',
            this.handleApiInformation,
            data
        );
    }

    inputChangeSearch=(data)=>{
        this.setState({
            inputSearch:data.target.value
        });
    }

    handleChooseItem=(items)=>{
        this.setState({itemChosen:items});
    }
    handleDeleteButton=()=>{
        this.setState({navigateDelete:true});
    }
    handleSearchButton=()=>{
        this.handleLoadInformation({
            search:this.state.inputSearch
        });
    }
}
 
export default AdminArticles;