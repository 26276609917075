import React, { Component } from 'react';
import './hr.css';

class Hr extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <hr className={'hr-component hr-page '+(this.props.className??'')} />
            </>
        );
    }
}
 
export default Hr;