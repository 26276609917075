import React, { Component } from 'react';
import Part from '../../part';
import './commentPart.css';
import Butten from '../../../../elementComponents/buttens/butten/butten';
import PageMessage from '../../../pageMessages/pageMessage/pageMessage';
import Form from '../../../../elementComponents/forms/form/form';
import Textarea from '../../../../elementComponents/inputs/textareas/textarea/textarea';
import Cookies from 'js-cookie';
import { Navigate } from 'react-router-dom';

class CommentPart extends Component {
    state = {  
        page:false,
        navigate:false,
        inputText:''
    } 
    render() { 
        return (
            <>
                {this.state.navigate==true ? <Navigate to='/login'/> : ''}
                {this.state.page==true ? <PageMessage onClose={this.handleClose}>
                    <Form>
                        <Textarea onChange={this.inputChangeText} placeholder='متن نظر'></Textarea>
                        <Butten onClick={this.handleCreate}>
                            ایجاد
                        </Butten>
                    </Form>
                </PageMessage> : ''}

                <Part width='1000' className='commentPart-component commentPart-page default-glass'>
                    <div className="commentPart-title-component default-twoItem-part">
                        <div>
                            <Butten onClick={this.handleCommentButton}>
                                ایجاد نظر
                            </Butten>
                        </div>
                        <div>
                            <h1>نظرات</h1>
                        </div>
                    </div>
                    <div className='commentPart-content-component'>
                        {
                            this.props.comments.map(element=>(
                                <div>
                                <div>
                                    <img src={element.user.profile??"/images/user.png"} alt="" />
                                    <p>{element.user.name??element.user.username}</p>
                                </div>
                                <p>
                                    {element.comment}
                                </p>
                                </div>
                            ))
                        }
                    </div>
                    {this.props.children}
                </Part>
            </>
        );
    }

    inputChangeText=(data)=>{
        this.setState({
            inputText:data.target.value
        });
    }

    handleClose=()=>{
        this.setState({
            page:false
        });
    }
    handleCreate=()=>{
        this.handleClose();
        if(this.props.onCreate != undefined){
            this.props.onCreate(this.state.inputText);
        }
    }
    handleCommentButton=()=>{
        if(Cookies.get('token')==undefined){
            this.setState({
                navigate:true
            });
        }
        else{
            this.setState({
                page:true
            });
        }
    }
}
 
export default CommentPart;