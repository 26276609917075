import { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import API from "../../../api";
import TeacherPanel from "../TeacherPanel";
import PanelPart from "../../../components/mainComponents/mainPartPage/panelParts/panelPart";
import Form from "../../../components/elementComponents/forms/form/form";
import Input from "../../../components/elementComponents/inputs/input/input";
import Butten from "../../../components/elementComponents/buttens/butten/butten";
import SelectInput from "../../../components/elementComponents/inputs/selectInputs/selectInput/selectInput";
import Textarea from "../../../components/elementComponents/inputs/textareas/textarea/textarea";

const TeacherLessonUpdate = (props) => {

    const loc = useLocation();
    var params=useParams();
    const api=new API();
    console.log(loc);

    const [item , setItem] = useState(loc.state ? loc.state.item??{} : {});
    const [navigate , setNavigate] = useState('');
    const [inputDescription , setInputDescription] = useState(item.description??'');
    const [inputTitle , setInputTitle] = useState(item.title??'');
    const [inputFile , setInputFile] = useState('');
    const [inputPeriod , setInputPeriod] = useState('');
    const [periodItems , setPeriodItems] = useState('');

    useEffect(() => {
        api.send(
            props.addMessage,
            'get',
            'teacher/period/all',
            handleApiPeriodInformation,
            {},
            ()=>{},
            false
        );
    }, []);



    return ( 
        <>
            {navigate==true ? <Navigate to='/panel/teacher/lesson'/> : ''}
            <TeacherPanel active='lesson'>
                <PanelPart title={'ویرایش درس #'+params.id} description='در این بخش شما می توانید درس مورد نظر خود را با تغییر فیلد های زیر ویرایش کنید'>
                    <Form>
                        <div className='default-item-center-part'>
                            <Input width='560' placeholder="عنوان را وارد نمایید" onChange={inputChangeTitle} value={item.title??''}>عنوان</Input>
                            <Textarea width='560' onChange={inputChangeDescription} value={item.description??''}>توضیحات</Textarea>
                            <Input type='file' width='560' onChange={inputChangeFile}>عکس</Input>
                            <SelectInput width='560' options={['دوره مد نظر خود را وارد نمایید',...periodItems]} onChange={inputChangePeriod}>انتخاب دوره</SelectInput>
                        </div>
                        <Butten onClick={handleUpdateButton}>ویرایش</Butten>
                        <Butten to='/panel/teacher/period'>بازگشت</Butten>
                    </Form>
                </PanelPart>
            </TeacherPanel>
        </>
    );

    function handleApiPeriodInformation(data){
        var periods=[];
        data.info.forEach(element => {
            periods.push([element.title,element.id]);
        });
        setPeriodItems(periods);
    }

    function inputChangeTitle(e){
        setInputTitle(e.target.value);
    }
    function inputChangeDescription(e){
        setInputDescription(e.target.value);
    }
    function inputChangeFile(e){
        setInputFile(e.target.files[0]);
    }
    function inputChangePeriod(e){
        setInputPeriod(e.target.value);
    }

    function handleUpdateButton(){
        var data = new FormData();
        data.append('title', inputTitle);
        data.append('description', inputDescription);
        data.append('period', inputPeriod);
        data.append('img_file', inputFile);
        
        api.send(
            props.addMessage,
            'post',
            'teacher/lesson/update/'+params.id,
            ()=>{setNavigate(true)},
            data
        );
    }
}
 
export default TeacherLessonUpdate;