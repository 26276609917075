import Header from '../../../components/mainComponents/headers/header/header';
import Footer from '../../../components/mainComponents/footers/footer/footer';
import SingleItemPart from '../../../components/mainComponents/mainPartPage/singleItemParts/singleItemPart/singleItemPart';
import { Navigate, useParams } from 'react-router-dom';
import API from '../../../api';
import { useState,useEffect } from 'react';
import ItemSuspended from '../../../components/elementComponents/ItemSuspendeds/itemSuspended/itemSuspended';
import ItemSuspended3 from '../../../components/elementComponents/ItemSuspendeds/itemSuspended-3/itemSuspended';
import ShowItemPart3 from '../../../components/mainComponents/mainPartPage/showItemParts/showItemPart-3/showItemPart';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import Item from '../../../components/mainComponents/mainPartPage/showItemParts/items/item/item';
import PageMessage from '../../../components/mainComponents/pageMessages/pageMessage/pageMessage';
import Input from '../../../components/elementComponents/inputs/input/input';
import Form from '../../../components/elementComponents/forms/form/form';
import Hr from '../../../components/elementComponents/hrs/hr/hr';
import WidgetPart2 from '../../../components/mainComponents/mainPartPage/widgetParts/widgetPart-2/widgetPart';

const ShowPeriod = (props) => {

    var params=useParams();
    var api=new API();


    const [articleData,setArticleData]=useState([]);
    const [data,setData]=useState([]);
    const [inputPeriodPass,setInputPeriodPass]=useState([]);
    const [pageMessageJoin,setPageMessageJoin]=useState(false);
    const [navigate,setNavigate]=useState(false);

    useEffect(() => {
        api.send(
            props.addMessage,
            'get',
            'period/show/'+params.id??'',
            handleApiInformation,
            {},
            ()=>{setNavigate(true)}
        );
        api.send(
            props.addMessage,
            'get',
            'main',
            (Adata)=>{setArticleData(Adata.articles)},
            {},
            ()=>{},
            false
        );
    }, []);

    return ( 
        <>
            {navigate==true ? <Navigate to='/'/> : ''}
            {pageMessageJoin==true ? <PageMessage onClose={handleCloseJoinMessage}>
                <Form className='default-line-items'>
                    <Input width='280' placeholder='please write pass ...' type='password' onChange={inputChangePass}>گذرواژه</Input>
                    <Butten onClick={handlePeriodPassButton}>ورود به دوره</Butten>
                </Form>
            </PageMessage> : ''}


            <Header/>

            <div className="main-value-page">
                <SingleItemPart file={data.image_file} className="singleItemPart">
                    <Hr/>
                    <br />
                    <h2>{data.title}</h2>
                    <br />
                    <p>{data.description}</p>
                    <br />
                    {
                        data.yourJoin==false ? <Butten onClick={handleJoinButton}>عضو شدن</Butten> :
                        <Butten onClick={handleUnJoinButton}>بیرون آمدن</Butten>
                    }
                    <ItemSuspended bottom="-50" left="-60"/>
                    <ItemSuspended3 top="-80" left="-80"/>
                    <ItemSuspended3 bottom="-170" right="-80"/>
                </SingleItemPart>

                <WidgetPart2>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-description" width={87} height={87} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                <path d="M9 17h6" />
                <path d="M9 13h6" />
                </svg>
                <p>سایتی با مقالات بسیار برای یادگیری و کمک به استفاده از سایت</p>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-notebook" width={87} height={87} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
                <path d="M13 8l2 0" />
                <path d="M13 12l2 0" />
                </svg>
                <p>همراه با دروس معتبر از طرف مدرسین شما در دوره ها</p>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width={87} height={87} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
                <p>شما به عنوان معلم در این سایت می توانید دوره های خود را ثبت کنید</p>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width={87} height={87} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" />
                <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4" />
                </svg>
                <p>همچنین شما در اینجا به عنوان دانشجو می توانید از دوره و مطالب مدرسانتان استفاده کنیم </p>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ballpen" width={87} height={87} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14 6l7 7l-4 4" />
                <path d="M5.828 18.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4z" />
                <path d="M4 20l1.768 -1.768" />
                </svg>
                <p>در این سایت به عنوان دانشجو شما هنگام ثبت نام در دوره ها باید تکالیف را انجام دهید</p>
              </li>
            </WidgetPart2>

                <ShowItemPart3 title='مقالات'>
                    {
                        articleData.map((article)=>(
                            <Item>
                                <img src={article.image_file} alt="" />
                                <div>
                                <h3>{article.title}</h3>
                                <p>{article.body}</p>
                                <Butten to={'/article/'+article.id} fontSize='14'>نمایش</Butten>
                                </div>
                            </Item>
                        ))
                    } 
                </ShowItemPart3>
                <br />
            </div>

            <Footer/>
        </>
    );

    function handleApiInformation(data){
        setData(data.info);
    }
    function handleApiJoinPass(){
        setPageMessageJoin(false);
    }

    function inputChangePass(e){
        setInputPeriodPass(e.target.value);
    }

    function handleJoinButton(){
        if(data.type=='secret'){
            setPageMessageJoin(true);
        }
        else{
            api.send(
                props.addMessage,
                'post',
                'period/join/'+params.id,
            );
        }
    }
    function handleUnJoinButton(){
        api.send(
            props.addMessage,
            'get',
            'period/unjoin/'+params.id,
        );
    }
    function handleCloseJoinMessage(){
        setPageMessageJoin(false);
    }
    function handlePeriodPassButton(){
        api.send(
            props.addMessage,
            'post',
            'period/join/'+params.id,
            handleApiJoinPass,
            {password:inputPeriodPass}
        );
    }
}
 
export default ShowPeriod;