import React, { Component } from 'react';
import AdminPanel from '../AdminPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import Input from '../../../components/elementComponents/inputs/input/input';
import Form from '../../../components/elementComponents/forms/form/form';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import API from '../../../api';
import { Navigate } from 'react-router-dom';
import Textarea from '../../../components/elementComponents/inputs/textareas/textarea/textarea';

class AdminArticleCreate extends Component {
    state = { 
        inputTitle:'',
        inputDescription:'',
        inputFile:null,
        navigate:false
    } 
    api=new API();

    render() { 
        return (
            <>
                {this.state.navigate==true ? <Navigate to={'..'}/> : ''}
                <AdminPanel active="article">
                    <PanelPart title='ایجاد مقاله جدید در سایت' description="در این بخش میتوانید مقاله ای جدید به سایت همیار معلم اضافه نمایید">
                        <Form>
                            <Input  placeholder="عنوان را وارد نمایید" onChange={this.inputChangeTitle} width='2000'>عنوان</Input>
                            <Textarea placeholder="توضیحات مقاله را بنویسید" onChange={this.inputChangeDescription}>توضیحات</Textarea>
                            <Input  type="file" onChange={this.inputChangeFile} width='2000'>فایل</Input>
                            <br />
                            <div>
                                <Butten onClick={this.handleCreateButton}>ایجاد</Butten>
                                <Butten to='/panel/admin/article'>بازگشت</Butten>
                            </div>
                        </Form>
                    </PanelPart>
                </AdminPanel>
            </>
        );
    }

    inputChangeTitle=(e)=>{
        this.setState({inputTitle:e.target.value});
    }
    inputChangeDescription=(e)=>{
        this.setState({inputDescription:e.target.value});
    }
    inputChangeFile=(e)=>{
        this.setState({inputFile:e.target.files[0]});
    }


    handleCreateButton=()=>{
        var data = new FormData() 
        data.append('title', this.state.inputTitle);
        data.append('body', this.state.inputDescription);
        data.append('img_file', this.state.inputFile);
        this.api.send(
            this.props.addMessage,
            'post',
            'admin/article/create',
            ()=>{this.setState({navigate:true})},
            data
        );
    }
}
 
export default AdminArticleCreate;