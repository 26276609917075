import { Component } from 'react';
import Header from '../../components/mainComponents/headers/header/header';
import Part from '../../components/mainComponents/mainPartPage/part';
import ItemSuspended4 from '../../components/elementComponents/ItemSuspendeds/itemSuspended-4/itemSuspended';
import ItemSuspended from '../../components/elementComponents/ItemSuspendeds/itemSuspended/itemSuspended';
import ItemSuspended3 from '../../components/elementComponents/ItemSuspendeds/itemSuspended-3/itemSuspended';
import Butten from '../../components/elementComponents/buttens/butten/butten';
import WidgetPart2 from '../../components/mainComponents/mainPartPage/widgetParts/widgetPart-2/widgetPart';
import Item from '../../components/mainComponents/mainPartPage/showItemParts/items/item/item';
import ShowItemPart3 from '../../components/mainComponents/mainPartPage/showItemParts/showItemPart-3/showItemPart';
import Footer from '../../components/mainComponents/footers/footer/footer';
import API from '../../api';
import SlidingContent from '../../components/elementComponents/slidingContents/slidingContent/slidingContent';

class App extends Component {
    state={
       articles:[],
       periods:[],
    }

    api=new API();

    componentDidMount(){
      this.api.send(
        this.props.addMessage,
        'get',
        'main',
        this.handleApiInformation,
        {},
        ()=>{},
        false
      );
    }

    render(){
      return(
        <>
          <Header />

          <div className='main-value-page'>

            <Part className='default-two-side main-section' width='1400'>
              <div className='default-glass default-color-site'>
                <h1>
                   سایت همیار مدرس سایتی برای یادگیری بیشتر و سهولت رابطه مدرسان و دانشجویان به صورت مجازی 
                </h1>
                <br />
                <h3>
                سایت همیار مدرس سایتی است برای سهولت رابطه بین مدرسان دوره ها و آموزشگاه های مختلف و دانش آموخته های این مدرسان در فضای مجازی . در واقع گزینه هایی که این سایت به کاربر هایش (مدرسان) میدهد ثبت کردن فایل و مطلب برای دانشجو ها و قرار دادن تکلیف برای آنها است . همچنین از سرویس هایی که قرار است به معلم ها داده شود می توان قابلیت گذاشتن آزمون برای دانش آموزان است. همچنین چت و پیامرسانی کوچک بین معلم و دانش آموزان.

این سایت یک وبلاگ در بحث آموزش نیز هست که با قرار دادن مقالاتی توسط ادمین سایت شما را از روش های تدرس و کار با سایت و ... مطلع می کند

                </h3>
                <br />
                <Butten color='#4479ff' className='default-color-dashboard'>Articles</Butten>
                <Butten color='#4479ff' className='default-color-dashboard'>Products</Butten>
              </div>
              <div>
                <img src="/images/sitePicture6.png" alt=""/>
              </div>

              <ItemSuspended3 top='-10' right='-10'/>
              <ItemSuspended3 bottom='10' right='600'/>
              <ItemSuspended top='10' left='55'/>
              <ItemSuspended4 bottom='10' right='40'/>
              <ItemSuspended bottom='0' left='80'/>
            </Part>

            <WidgetPart2>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-file-description" width={87} height={87} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z" />
                <path d="M9 17h6" />
                <path d="M9 13h6" />
                </svg>
                <p>سایتی با مقالات بسیار برای یادگیری و کمک به استفاده از سایت</p>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-notebook" width={87} height={87} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
                <path d="M13 8l2 0" />
                <path d="M13 12l2 0" />
                </svg>
                <p>همراه با دروس معتبر از طرف مدرسین شما در دوره ها</p>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-user" width={87} height={87} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
                </svg>
                <p>شما به عنوان معلم در این سایت می توانید دوره های خود را ثبت کنید</p>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width={87} height={87} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" />
                <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4" />
                </svg>
                <p>همچنین شما در اینجا به عنوان دانشجو می توانید از دوره و مطالب مدرسانتان استفاده کنیم </p>
              </li>
              <li>
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-ballpen" width={87} height={87} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M14 6l7 7l-4 4" />
                <path d="M5.828 18.172a2.828 2.828 0 0 0 4 0l10.586 -10.586a2 2 0 0 0 0 -2.829l-1.171 -1.171a2 2 0 0 0 -2.829 0l-10.586 10.586a2.828 2.828 0 0 0 0 4z" />
                <path d="M4 20l1.768 -1.768" />
                </svg>
                <p>در این سایت به عنوان دانشجو شما هنگام ثبت نام در دوره ها باید تکالیف را انجام دهید</p>
              </li>
              <ItemSuspended3 bottom='-87' left='-29'/>
              <ItemSuspended top='40' right='20' color='#7affe0'/>
            </WidgetPart2>

            <ShowItemPart3 addResize = {this.props.addResizeFunction} title='مقالات' description='مقالات علمی وبسایت'>
            {this.state.articles.map(article => (
                  <Item>
                    <img src={article.image_file} alt="Article-Image" />
                    <div>
                        {/* <InformationBar stars="5.0" type="مقالات"/> */}
                        <h3>{article.title}</h3>
                        <p>
                          {article.body}
                        </p>
                        {/* <Butten to={'/article/'+article.id} fontSize='14'>نمایش</Butten> */}
                    </div> 
                  </Item>
              ))}    
            </ShowItemPart3>


            <Part className='default-two-side main-section default-color-site' width='1220'>
              <div>
                <img src="/images/sitePicture3.png" alt=""/>
              </div>
              <div className='default-glass'>
                <h1>
                به عنوان دانشجو چه کار کنم؟؟؟
                </h1>
                <br />
                <h3>
                به عنوان دانشجو شما باید ابتدا در این نقش ثبت نام کنید  و وارد بخش داشبورد شوید . سپس با جستجو در سایت دوره ای که می خواهید را انتخاب و روی گزینه عضو شدن کلیک کنید . هچنین اگر دوره خاصی از طرف مدرستان برای شما معرفی شده آنرا جست و جو و با رمزی که از مدرستان گرفته اید وارد شوید .

بعد از آن می توانید درون داشبورد خود وارد بخش دوره ها شوید . و توضیحات و لیستی از دوره هایی که در آن ثبت نام کرده اید را ببینید . به همراه تکالیف فعلی دوره و وضعیت و محتوا های دوره .

شما در داشبورد خود نیز می توانید تمام تکالیف خود را مشاهده و اگر آنها را به پایان رسانده اید ثبت کنید 

                </h3>
                <br />
              </div>

              <ItemSuspended3 top='-10' right='-10'/>
              <ItemSuspended3 bottom='-20' right='570'/>
              <ItemSuspended4 top='10' left='20'/>
            </Part>

            <ShowItemPart3 addResize = {this.props.addResizeFunction} title='دوره ها' description='دوره های مدرسان'>
              {this.state.periods.map( period => (
                <Item>
                    <img src={period.image_file} alt="" />
                    <div>
                        {/* <InformationBar stars="5.0" type={period.type+' | '+period.status}/> */}
                        <h3>{period.title}</h3>
                        <p>{period.description}</p>
                        {/* <Butten to={'/period/'+period.id}  fontSize='14'>نمایش</Butten> */}
                    </div>
                </Item>
              ))}
            </ShowItemPart3>

            <Part width='900' className='default-line-items'>
              <h1 style={{color:'white'}}>چگونه فعالیت مناسبی در سایت داشته باشم?</h1>
              <br />
              <SlidingContent title='تکالیف دوره چگونه انجام می شود'>
                در این سایت هنگامی که شما وارد یک دوره می شوید در واقع تکالیفی که قبل حضور شما در سایت بوده به شما نمایش داده نمی شود زیرا برای شما حضور در کلاس از هنگام ثبت نام در دوره لحاظ می شود بنابراین در داشبورد خود سعی کنید تکالیفی که برای شما وضعیت انجام نشده دارند را انجام داده و دروس را متوجه شوید و از دوره کمال استفاده را داشته باشید
              </SlidingContent>
              <SlidingContent title='سعی کنید مقالات جدید را دنبال کنید'>
                در اینجا در هر مدت از طرف ادمین سایت مقالاتی در سایت بارگذاری می شود این مقالات گاهی برای راهنمایی شما برای استفاده بهینه و درست از سایت می باشد و گاهی هم برای اینکه بتوانید به عنوان معلم چگونه دروس را به دانشجو ها بفهمانید یا به عنوان دانشجو چگونه همراه دوره یادگیری خود را ادامه دهید
              </SlidingContent>
              <SlidingContent title='چطور به صورت مجازی امتحان هایمان را بدهیم'>
                در هنگامی که در دوره شما امتحانی قرار داده می شود شما باید این امتحان را به صورت مجازی پاسخ دهید بنابراین لازم است که آرامش کافی و اینترنت مناسب داشته باشید و سعی کنید همراه خود قلم و کاغذی برای استفاده به عنوان چرک نویس داشته باشید سپس با توجه به زمانی که معلمتان صلاح دیده آزمون را انجام دهید . اگر به صورت غیر منتظره به دلیل اینترنت یا چیزی دیگراز وبسایت بیرون رفتید نگران نباشید چون آزمون شما بسته نخواهد شد ولی زمان شما در حال رد شدن خواهد بود بنابراین به سرعت به وبسایت بازگردید
              </SlidingContent>
              <ItemSuspended3 top='-10' left='-10'/>
              <ItemSuspended3 bottom='10' right='-10' size='140' color='black'/>
            </Part>

            <Part className='default-two-side main-section default-color-site' width='1150'>
              <div className='default-glass'>
                <h1>
                چطور به عنوان معلم فعالیت خود را شروع کنیم؟ 
                </h1>
                <h3>
                 وارد بخش داشبورد خود می شویم 

در بخش منو داشبورد می توانیم گزینه ای با نام "دوره ها" مشاهده کنیم که وارد آن می شویم. درآن بخش جدولی خالی مشاهده می کنیم با چند دکمه در بالای آن. کار این بخش ساخت دوره  و ویرایش تنظیمات و حذف دوره هایی است که به عنوان معلم می توانید آنها را در لیست دوره های خود داشته باشید. همچنین شما می توانید در ساخت دوره مربوط به خود آن را خصوصی کنید و برای دوره خود رمزی خصوصی داشته باشید.  

در بخش منو داشبورد گزینه ای  دیگر با نام تکالیف می باشد که کارش قرار دادن تکالیف برای دانش آموزان مختلف یک داره است . وقتی که شما وارد این بخش می شوید لیستی از تکالیف که برای دوره ها گاشتید میبیند و می توانید آنها را ویرایش و بررسی نمایید


                </h3>
                <br />
              </div>
              <div>
                <img src="/images/sitePicture5.png" alt=""/>
              </div>

              <ItemSuspended3 bottom='10' right='-10'/>
              <ItemSuspended top='0' left='-10'/>
              <ItemSuspended3 bottom='-10' left='-10'/>
            </Part>

          </div>


          <Footer/>
        </>
      );
    }

    handleApiInformation=(data)=>{
      this.setState({
        articles:data.articles,
        periods:data.periods
      });
    }
}

export default App;
 