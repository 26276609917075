import { Navigate, useLocation } from "react-router-dom";
import API from "../../../api";

const TeacherExamDelete = (props) => {
    var loc=useLocation();
    var api=new API();

    var items='';
    loc.state.items.forEach(element => {
        items=items+element.id+',';
    });
    api.send(
        props.addMessage,
        'post',
        'teacher/exam/delete',
        ()=>{},
        {items}
    );
    return ( 
        <>
            <Navigate to='/panel/teacher/exam'/>
        </>
     );
}
 
export default TeacherExamDelete;