import React, { Component } from 'react';
import './itemSuspended.css'

class ItemSuspended3 extends Component {
    state = { } 
    render() { 
        return (
            <>
                <div
                className={'item-suspended3-component item-suspended3-page '+(this.props.className??'')} 
                style={{
                    top:this.props.top+'px' ,
                    left:this.props.left+'px' ,
                    bottom:this.props.bottom+'px' ,
                    right:this.props.right+'px' ,
                    backgroundImage: `radial-gradient(${this.props.color??'#000000'} 3px, transparent 3px)`,
                    width:(this.props.size??'180')+'px',
                    height:(this.props.size??'180')+'px',
                }}>
                </div>
            </>
        );
    }
}
 
export default ItemSuspended3;