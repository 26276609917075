import React, { Component, createRef , useEffect } from 'react';
import './showItemPart.css'
import Part from '../../part';

class ShowItemPart3 extends Component {
    state = {  
        count:0
    } 
    itemsMenu = createRef();
    items = createRef();

    constructor(props){
        super(props);
        if(props.addResize){
            props.addResize(this.handleResize);
        }
    }

    render() { 
        return (
            <>
                <Part className={'showItemPart3-component showItemPart3-page default-glass '+(this.props.className??'')} width={this.props.width??"1360"}>
                    <div className='showItemPart3-title-component'>
                        <div>
                            <h1>{this.props.title??'Articles'}</h1>
                            <h3>{this.props.description??'lorem ipsum doloret'}</h3>
                        </div>
                        <div className='showItemPart3-Buttons-component'>
                            <div className="showItemPart3-left" onClick={this.handleLeftButton}>
                                <svg viewBox="0 0 256 512">
                                <path d="M31.7 239l136-136c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9L127.9 256l96.4 96.4c9.4 9.4 9.4 24.6 0 33.9L201.7 409c-9.4 9.4-24.6 9.4-33.9 0l-136-136c-9.5-9.4-9.5-24.6-.1-34z" />
                                </svg>
                            </div>
                            <div className="showItemPart3-right" onClick={this.handleRightButton}>
                                <svg viewBox="0 0 256 512">
                                <path d="M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className='showItemPart3-items-component' ref={this.items} >
                        <div className='showItemPart3-itemsMenu-component' ref={this.itemsMenu} style={{marginLeft:(-256 * this.state.count)+'px'}}>
                            {this.props.children}
                        </div>
                    </div>    
                </Part>
            </>
        );
    }

 
    handleResize=()=>{
        if(this.itemsMenu.current , this.items.current){
            this.itemsMenu.current.style.transform = ('translateX(' + ((this.items.current.offsetWidth/2) - 128) + 'px');
        }
    }
    handleLeftButton=()=>{
        if(this.itemsMenu.current.children.length>this.state.count+1){
            this.setState({
                count:this.state.count+1
            });
        }
    }

    handleRightButton=()=>{
        if(0<this.state.count){
            this.setState({
                count:this.state.count-1
            });
        }
    }
}
 
export default ShowItemPart3;