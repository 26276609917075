import React, { Component } from 'react';
import './searchBox.css';
import Input from '../../inputs/input/input';


class SearchBox extends Component {
    state = {  } 
    render() {
        return (
            <>
                <div className={'searchBox-component searchBox-page '+(this.props.className??'')} 
                style={{backgroundColor:this.props.color??'#fff' }} >
                    <Input 
                        className="searchBox-input-component" 
                        placeholder="enter your search please ..." 
                        onChange={this.props.onChange??(()=>{})} 
                        width={this.props.width??"175"}
                    />
                    <svg onClick={this.props.onClick??(()=>{})} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search searchBox-icon-component" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                        <path d="M21 21l-6 -6" />
                    </svg>
                </div>
            </>
        );
    }
}

export default SearchBox ;