import React, { Component } from 'react';
import TeacherPanel from './TeacherPanel';
import PanelPart from '../../components/mainComponents/mainPartPage/panelParts/panelPart';
import Form from '../../components/elementComponents/forms/form/form';
import Input from '../../components/elementComponents/inputs/input/input';
import Butten from '../../components/elementComponents/buttens/butten/butten';
import API from '../../api';

class TeacherDashboard extends Component {
    state = {  
        inputName:'',
        inputFile:'',
        inputPhone:'',
        inputSpecializedDegree:''
    } 

    api = new API();

    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'teacher/information/show',
            this.handleApiInformation,
            {},
            ()=>{},
            true
        );
    }

    render() { 
        return (
            <>
                <TeacherPanel>
                    <PanelPart title='اطلاعات اولیه' description='در این قسمت می توانید اطلاعات اولیه خود را برای قسمت های مختلف ثبت کنید'>
                        <Form>
                            <div className='default-item-center-part'>
                                <Input width='300' placeholder='usernsme' onChange={this.inputChangeFile} type='file'>عکس </Input>
                                <Input value={this.state.inputName} width='300' placeholder='نام نمایشی' onChange={this.inputChangeName}>نام نمایشی </Input>
                                <Input value={this.state.inputPhone} width='300' placeholder='شماره تلفن' onChange={this.inputChangePhone}>تلفن همراه </Input>
                                <Input value={this.state.inputSpecializedDegree} width='300' placeholder='مدرک تحصیلی' onChange={this.inputChangeSpecializedDegree}>مدرک تخصصی </Input>    
                            </div>
                            <br />
                            <Butten onClick={this.handleInformationButton}>ثبت اطلاعات اولیه</Butten>
                        </Form>
                    </PanelPart>
                </TeacherPanel>
            </>
        );
    }

    handleApiInformation = (data)=>{
        this.setState({
            inputName:data.info.name??'',
            inputPhone:data.info.phone??'',
            inputSpecializedDegree:data.info.specializeddegree??''
        });
    }

    inputChangePhone=(e)=>{
        this.setState({inputPhone:e.target.value});
    }
    inputChangeName=(e)=>{
        this.setState({inputName:e.target.value});
    }
    inputChangeFile=(e)=>{
        this.setState({inputFile:e.target.files[0]});
    }
    inputChangeSpecializedDegree=(e)=>{
        this.setState({inputSpecializedDegree:e.target.value});
    }

    handleInformationButton=()=>{
        var data = new FormData();
        data.append('name', this.state.inputName);
        data.append('phone', this.state.inputPhone);
        data.append('img_file', this.state.inputFile);
        data.append('specializeddegree', this.state.inputSpecializedDegree);
        
        this.api.send(
            this.props.addMessage,
            'post',
            'teacher/information/create',
            this.handleApiInformation,
            data
        );
    }
}
 
export default TeacherDashboard;