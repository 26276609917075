import React, { Component } from 'react';
import Part from '../../../components/mainComponents/mainPartPage/part';
import Header from '../../../components/mainComponents/headers/header/header';
import Footer from '../../../components/mainComponents/footers/footer/footer';
import Item from '../../../components/mainComponents/mainPartPage/showItemParts/items/item/item';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import ItemSuspended from '../../../components/elementComponents/ItemSuspendeds/itemSuspended/itemSuspended';
import API from '../../../api';

class Articles extends Component {
    state = { 
        items:[]
    } 
    
    api=new API();

    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'article/all',
            (data)=>{this.setState({items:data.info})}
        );
    }
    render() {
        return (
            <>
                <Header/>
                <div className='main-value-page'>
                    <Part className=' default-glass' width='900'>
                        <div className='default-alignItem-center'>
                            <h1 className='default-margin'>مقالات وبسایت همیار مدرس</h1>
                        </div>
                        <br />
                        بخش مقالات بخشی برای نمایش مطالب و مقالاتی است که برای آموزش و تدریس و کار با سایت استفاده می شود ومی توانید مقالاتی در این رابطه و دستورالعمل های آموزشی و کار با سایت را ببینید در پایین می توانید لیستی از آنها را که از طرف ادمین هستند مشاهده بفرمایید 
                        <ItemSuspended color='#b9e2ff' bottom='-50' right='15'/>
                    </Part>

                    <Part width="1200" className="default-item-center-part">
                    
                        {
                            this.state.items.map((item)=>{
                                return(
                                    <Item>
                                        <img src={item.image_file} alt="" />
                                        <div>
                                            <h3>{item.title}</h3>
                                            <p>{item.body}</p>
                                            <Butten fontSize="14" to={'/article/'+item.id}>نمایش</Butten>
                                        </div>
                                    </Item>
                                );
                            })
                        }
                        <ItemSuspended left="46" top="-17"/>
                    </Part>
                </div>

                <Footer/> 
            </>
        );
    }
}
 
export default Articles;