import React, { Component } from 'react';
import AdminPanel from '../AdminPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import API from '../../../api';
import ListPanel from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';

class AdminUsers extends Component {
    state = {  
        data:[]
    } 
    api = new API();

    componentDidMount(){
        this.handleLoadInformation();
    }
    render() { 
        return (
            <>
                <AdminPanel active='user'>
                    <PanelPart title='کاربران وبسایت' description='در این بخش می توانید اطلاعات افرادی که در سایت حضور دارند را ببینید'>
                        <ListPanel
                            data={this.state.data}
                            items={[
                                ['نام نمایشی',e=>e.name??'بدون نام نمایشی'],
                                ['نام کاربری',e=>e.username],
                                ['شغل',e=>e.job??'بدون اطلاعات'],
                                ['شماره تماس',e=>e.phone??'بدون اطلاعات']
                            ]}
                            buttons={['show']}
                        />
                    </PanelPart>
                </AdminPanel>
            </>
        );
    }

    handleApiInformation=(data)=>{
        this.setState({
            data:data.info
        });
    }

    handleLoadInformation=(data={})=>{
        this.api.send(
            this.props.addMessage,
            'get',
            'admin/user/all',
            this.handleApiInformation,
            data
        );
    }

}
 
export default AdminUsers;