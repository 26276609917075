import React, { Component } from 'react';
import TeacherPanel from '../TeacherPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import Form from '../../../components/elementComponents/forms/form/form';
import Input from '../../../components/elementComponents/inputs/input/input';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import { Navigate } from 'react-router-dom';
import API from '../../../api';
import SelectInput from '../../../components/elementComponents/inputs/selectInputs/selectInput/selectInput';
import Hr from '../../../components/elementComponents/hrs/hr/hr';

class TeacherPeriodCreate extends Component {
    state = {  
        inputTitle:'',
        inputDescription:'',
        inputType:'',
        inputFile:'',
        inputPass:'',
        showPassInput:false,
        navigate:false
    } 

    api=new API();

    render() { 
        return (
            <>
                {this.state.navigate==true ? <Navigate to={'/panel/teacher/period'}/> : ''}
                <TeacherPanel active='period'>
                    <PanelPart title='ایجاد یک دوره' description='شما می توانید با پر کردن فیلد های زیر یک دوره را بسازید دوره شما می تواند خصوصی و همراه با رمز عبور باشد . همچنین شما می توانید یک عکس را به عنوان عکس دوره خود وارد نمایید'>
                        <Form>
                            <div className='default-item-center-part'>
                                <Input width='730' placeholder='عنوان را وارد نمایید' onChange={this.inputChangeTitle}>عنوان</Input>
                                <Input width='730' placeholder='توضیحات را وارد نمایید' onChange={this.inputChangeDescription}>توضیحات</Input>
                                <SelectInput width='360' options={['نوع دوره را وارد کنید',['عمومی','public'],['خصوصی','secret']]} onChange={this.inputChangeType}>نوع</SelectInput>
                                {this.state.showPassInput==true ? <Input width='360' type='password' placeholder='please enter ...' onChange={this.inputChangePass}>پسورد دوره</Input> : ''}
                                <Input type='file' width='360' placeholder='please enter ...' onChange={this.inputChangeFile}>فایل</Input>
                            </div>
                            <Hr/>
                            <br />
                            <Butten onClick={this.handleCreateButton}>ایجاد دوره</Butten>
                            <Butten to='/panel/teacher/period'>برگشت</Butten>
                        </Form>
                    </PanelPart>
                </TeacherPanel>
            </>
        );
    }
    
    inputChangeTitle=(e)=>{
        this.setState({inputTitle:e.target.value});
    }
    inputChangeDescription=(e)=>{
        this.setState({inputDescription:e.target.value});
    }
    inputChangeFile=(e)=>{
        this.setState({inputFile:e.target.files[0]});
    }
    inputChangeType=(e)=>{
        if(e.target.value=='secret'){
            this.setState({showPassInput:true});
        }
        else{
            this.setState({showPassInput:false});
        }
        this.setState({inputType:e.target.value});
    }
    inputChangePass=(e)=>{
        this.setState({inputPass:e.target.value});
    }

    handleCreateButton=()=>{
        var data = new FormData();
        data.append('title', this.state.inputTitle);
        data.append('description', this.state.inputDescription);
        data.append('img_file', this.state.inputFile);
        data.append('type', this.state.inputType);
        data.append('password', this.state.inputPass);
        
        this.api.send(
            this.props.addMessage,
            'post',
            'teacher/period/create',
            ()=>{this.setState({navigate:true})},
            data
        );
    }
}
 
export default TeacherPeriodCreate;