import React, { Component } from 'react';
import './selectInput.css';

class SelectInput extends Component {
    state = {  } 
    render() { 
        var label='';
        if(this.props.children){
            label=<><label htmlFor={this.props.id??''}>{this.props.children}</label><br/></>;
        }
        return (
            <div className={'selectInput-component selectInput-page '+(this.props.className??'')}>
                {label}
                <select 
                style={{width:(this.props.width??'') + 'px'}}
                name={this.props.name??''} 
                id={this.props.id??''}
                onChange={this.props.onChange??(()=>{})}
                defaultValue={this.props.value??null}
                >
                    {this.props.options.map((item)=>
                    {
                        var value=item;
                        var name=item;
                        if(Array.isArray(item)){
                            var value=item[1];
                            var name=item[0];
                        }
                        return(<option value={value}>{name}</option>);
                    })}
                </select>
            </div>
        );
    }
 }
  
 export default SelectInput;