import TeacherPanel from '../TeacherPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import Form from '../../../components/elementComponents/forms/form/form';
import Input from '../../../components/elementComponents/inputs/input/input';
import Butten from '../../../components/elementComponents/buttens/butten/butten';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import SelectInput from '../../../components/elementComponents/inputs/selectInputs/selectInput/selectInput';
import { useState } from 'react';
import API from '../../../api';
import Textarea from '../../../components/elementComponents/inputs/textareas/textarea/textarea';

const TeacherPeriodUpdate = (props) => {

    const params=useParams();
    const loc = useLocation();
    const api=new API();

    const [item , setItem] = useState(loc.state ? loc.state.item??{} : {});
    const [navigate , setNavigate] = useState(false);
    const [inputType , setInputType] = useState('');
    const [inputDescription , setInputDescription] = useState(item.description ?? '');
    const [inputTitle , setInputTitle] = useState(item.title??'');
    const [inputFile , setinputFile] = useState('');
    const [inputPass , setInputPass] = useState('');
    const [inputStatus , setInputStatus] = useState('');
    const [showPassInput , setShowPassInput] = useState(false);

    return ( 
        <>
                 {navigate==true ? <Navigate to={'/panel/teacher/period'}/> : ''}
                 <TeacherPanel active='period'>
                     <PanelPart title={'ویرایش دوره ی  #'+params.id} description='در این بخش می توانید یکی از دوره هایی که ایجاد کردید را با آیدی که در بالا می بینید ویرایش نمایید همچنین می توانید وضعیت دوره را از حالت های شروع به کار یا در حال انچام یا تکمیل شده انتخاب نمایید'>
                         <Form>
                            <div className='default-item-center-part'>
                                <Input width='340' placeholder='please enter ...' onChange={inputChangeTitle} value={item.title}>عنوان</Input>
                                <Input type='file' width='340' placeholder='please enter ...' onChange={inputChangeFile} >فایل</Input>
                                <Textarea width='690' onChange={inputChangeDescription} value={item.description}>توضیحات</Textarea>
                                <SelectInput width='340' options={['یک گزینه را انتخاب کنید',['عمومی','public'],['خصوصی','secret']]} onChange={inputChangeType} >نوع</SelectInput>
                                <SelectInput width='340' options={['یک گزینه را انتخاب کنید',['در حال آغاز','starting'],['در حال تکمیل','compliting'],['تکمیل شده','completed']]} onChange={inputChangeStatus}>وضعیت</SelectInput>
                                {showPassInput==true ? <Input width='300' type='password' placeholder='please enter ...' onChange={inputChangePass} >پسورد دوره</Input> : ''}
                             </div>
                             <br />
                             <Butten onClick={handleUpdateButton}>ویرایش</Butten>
                             <Butten to='/panel/teacher/period'>بازگشت</Butten>
                         </Form>
                     </PanelPart>
                 </TeacherPanel>
             </>
     );

     function inputChangeTitle(e){
        setInputTitle(e.target.value);
    }
    function inputChangeDescription(e){
        setInputDescription(e.target.value);
    }
    function inputChangeFile(e){
        setinputFile(e.target.files[0]);
    }
    function inputChangeType(e){
        if(e.target.value=='secret'){
            setShowPassInput(true);
        }
        else{
            setShowPassInput(false);
        }
        setInputType(e.target.value);
    }
    function inputChangePass(e){
        setInputPass(e.target.value);
    }
    function inputChangeStatus(e){
        setInputStatus(e.target.value);
    }

    function handleUpdateButton(){
        var data = new FormData();
        data.append('title', inputTitle);
        data.append('description', inputDescription);
        data.append('img_file', inputFile);
        data.append('type', inputType);
        data.append('password', inputPass);
        data.append('status', inputStatus);
        
        api.send(
            props.addMessage,
            'post',
            'teacher/period/update/'+params.id,
            ()=>{setNavigate(true)},
            data
        );
    }
}
 
export default TeacherPeriodUpdate;