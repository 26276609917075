import React, { Component } from 'react';
import './item.css';


class Item extends Component {
    state = {  } 
    render() { 
        return (
            <>
                <div className={'item-component item-page'+(this.props.className??'')}>
                    {this.props.children}
                </div>
            </>
        );
    }
}
 
export default Item;