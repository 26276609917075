import React, { Component } from 'react';
import DashboardMenuPart from '../../components/mainComponents/mainPartPage/dashdoardMenuParts/dashboardMenuPart/dashboardMenuPart';
import { Link,Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';

class StudentPanel extends Component {
    state = {  
        dashoardClass:'dashboardMenuPart-chosenItem-component',
        periodClass:'',
        lessonClass:'',
        drillClass:'',
        teacherClass:'',
        examClass:'',
        chatClass:''
    } 

    constructor(props){
        super(props);
        if(Cookies.get('role')!='student'){
            this.state.navigate=true;
        }

        if(props.active!=undefined){
            this.state.dashoardClass='';
            switch (props.active) {
                case 'period':
                    this.state.periodClass='dashboardMenuPart-chosenItem-component';
                    break;
                case 'lesson':
                    this.state.lessonClass='dashboardMenuPart-chosenItem-component';
                    break;
                case 'drill':
                    this.state.drillClass='dashboardMenuPart-chosenItem-component';
                    break;
                case 'exam':
                    this.state.examClass='dashboardMenuPart-chosenItem-component';
                    break;
                case 'chat':
                    this.state.chatClass='dashboardMenuPart-chosenItem-component'
            }
        }
    }

    render() { 
        return (
            <>
            {this.state.navigate==true ? <Navigate to={'/login'}/> : ''}
            <div className='main-value-panels-page'>
                <DashboardMenuPart>
                    <ul>
                        <li className={this.state.dashoardClass}>
                            <Link to={'/panel/student'}>داشبورد</Link>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />
                            <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6" />
                            </svg>
                        </li>
                        <li className={this.state.periodClass}>
                            <Link to={'/panel/student/period'}>دوره ها</Link>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-school" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M22 9l-10 -4l-10 4l10 4l10 -4v6" />
                            <path d="M6 10.6v5.4a6 3 0 0 0 12 0v-5.4" />
                            </svg>
                        </li>
                        <li className={this.state.lessonClass}>
                            <Link to={'/panel/student/lesson'}>دروس</Link>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-book" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M3 19a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                            <path d="M3 6a9 9 0 0 1 9 0a9 9 0 0 1 9 0" />
                            <path d="M3 6l0 13" />
                            <path d="M12 6l0 13" />
                            <path d="M21 6l0 13" />
                            </svg>
                        </li>
                        <li className={this.state.drillClass}>
                            <Link to={'/panel/student/drill'}>تکالیف</Link>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-notebook" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
                            <path d="M13 8l2 0" />
                            <path d="M13 12l2 0" />
                            </svg>
                        </li>
                        <li className={this.state.examClass}>
                            <Link to={'/panel/student/exam'}>امتحان ها</Link>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-notebook" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M6 4h11a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-11a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1m3 0v18" />
                            <path d="M13 8l2 0" />
                            <path d="M13 12l2 0" />
                            </svg>
                        </li>
                        <li className={this.state.chatClass}>
                            <Link to={'/chats'}>چت ها</Link>
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-message">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M8 9h8" />
                            <path d="M8 13h6" />
                            <path d="M18 4a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-5l-5 3v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12z" />
                            </svg>
                        </li>
                    </ul>
                </DashboardMenuPart>

                {this.props.children}

            </div>
            </>
        );
    }
}
 
export default StudentPanel;