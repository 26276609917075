import React, { Component } from 'react';
import StudentPanel from '../StudentPanel';
import PanelPart from '../../../components/mainComponents/mainPartPage/panelParts/panelPart';
import API from '../../../api';
import ListPanel2 from '../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel';

class StudentLessons extends Component {
    state = {  
        data:[]
    }
    
    api=new API();
    
    componentDidMount(){
        this.api.send(
            this.props.addMessage,
            'get',
            'student/lesson/all',
            this.handleApiInformation
        );
    }

    render() { 
        return (
            <>
                <StudentPanel active='lesson'>
                    <PanelPart title='دروس دوره ها' description='در این بخش می توانید درس هایی که در دوره های مختلفی که شما در آن ثبت نام کرده اید را مشاهده فرمایید و اطلاعات آن را مشاهده فرمایید'>
                        <ListPanel2
                        data={this.state.data}
                        buttons={['show']}
                        items={[
                            ['عنوان',e=>e.title],
                            ['فایل',e=>e.image_file,'file'],
                            ['توضیحات',e=>e.description],
                            ['دوره',element=>(element.period.title)]]}
                        />
                    </PanelPart>
                </StudentPanel>
            </>
        );
    }

    handleApiInformation=(data)=>{
        this.setState({data:data.info});
    }
}
 
export default StudentLessons;