import React, { Component } from 'react';
import './listPanel.css';
import Butten from '../../../../../elementComponents/buttens/butten/butten';

class ListPanel2 extends Component {
    state = {
        ChosenItems:[],
        TableButtons:[]
    }

    constructor(props){
        super(props);
        if(props.buttons!=undefined){
            this.state.TableButtons=props.buttons;
        }
    }



    render() {
        return (
            <div className={'listPanel2-component listPanel2-page default-scrollbar-style'+(this.props.className??'')}>
                
                <table>
                    <thead>
                        <tr>
                            {this.props.items.map((item)=>(
                                <>
                                    <th>{Array.isArray(item) ? item[0] : item}</th>
                                </>
                            ))}
                            {this.state.TableButtons[0]!=undefined ? <th>گزینه ها</th> : ''}
                            {this.props.onChoose ? <th className='listPanel2-input-component'></th> : ''}
                        </tr>
                    </thead>

                    <tbody>
                        {this.props.data.map((element)=>{
                            return(
                            <tr>

                                {this.props.items.map(item => {
                                    var showData= Array.isArray(item) ? item[1](element) : element[item];
                                    if(Array.isArray(item)){
                                        return(
                                            <td>{item[2]!=undefined && item[2].includes('file') ? <img src={showData} alt="ImageFile" /> : <p>{showData}</p>}</td>
                                        );
                                    }
                                    else{
                                        return(
                                            <td>{item.includes('file') ? <img src={showData} alt="ImageFile" /> : <p>{showData}</p>}</td>
                                        );
                                    }
                                })}

                                {this.state.TableButtons[0]!=undefined ? 
                                    <td>
                                        <div className='listPanel2-buttons-component'>
                                            {this.state.TableButtons.map(button => {
                                                switch (button) {
                                                    case 'show':
                                                        return(
                                                            <Butten className='default-not-shadow default-not-bgc' to={''+element.id}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-clipboard" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="blue" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                                                                <path d="M9 3m0 2a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v0a2 2 0 0 1 -2 2h-2a2 2 0 0 1 -2 -2z" />
                                                                </svg>
                                                            </Butten>
                                                        );
                                                        break;
                                                    case 'edit':
                                                        return(
                                                            <Butten className='default-not-shadow default-not-bgc' onClick={this.props.onEdit ? ()=>{this.props.onEdit(element)} : (()=>{})} to={this.props.onEdit ? '' : ('update/'+element.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="red" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="icon icon-tabler icons-tabler-outline icon-tabler-edit">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
                                                                <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
                                                                <path d="M16 5l3 3" />
                                                                </svg>
                                                            </Butten>
                                                        )
                                                        break;
                                                    default:
                                                        return(
                                                            <Butten className='default-not-shadow' fontSize='13' bgc='#cce3ff' onClick={()=>{button[1](element)}}>
                                                                {button[0] }
                                                            </Butten>
                                                        );
                                                }
                                            })}
                                        </div>
                                    </td>
                                : '' }

                                {this.props.onChoose ? <td><input type="checkbox"  onClick={()=>{this.handleCheckBoxClick(element)}}/></td> : ''}
                            </tr>
                            );
                        })}                                   
                    </tbody>
                </table>
            </div>
        );
    }

    handleCheckBoxClick=(element)=>{
        var array=this.state.ChosenItems;
        
        if(array.indexOf(element) != -1) {
            array.splice(array.indexOf(element),1);
        }
        else {
            array.push(element);
        }

        if(this.props.onChoose!=undefined){
             this.props.onChoose(array);
        }

        this.setState({
            ChosenItems:array
        });
    }
}
 
export default ListPanel2;