import React, { Component } from 'react';
import './singleItemPart.css';
import Part from '../../part';

class SingleItemPart extends Component {
    state = {  } 
    render() { 

        var file = <></>;
        if(this.props.file!=undefined) {
            if (this.props.file.split('.')[this.props.file.split('.').length-1]=='mp4' ||
                this.props.file.split('.')[this.props.file.split('.').length-1]=='webm' ||
                this.props.file.split('.')[this.props.file.split('.').length-1]=='avi'){
             file=<video src={this.props.file} poster={this.props.poster??''} controls>
                   <source/>
             </video>
            }
            else{
                file=<img src={this.props.file} alt={this.props.alt??'imageOpPost'} /> 
            }
        }
        return (
            <>
                <Part className={"singleItemPart-component singleItemPart-page default-glass "+(this.props.className??'')} width={this.props.width??"1200"} >
                    {file}
                    <div className='singleItemPart-textBox-component'>
                        {this.props.children}
                    </div>
                </Part>
            </>
        );
    }
}
 
export default SingleItemPart;