import ListPanel2 from "../../../components/mainComponents/mainPartPage/panelParts/listPanels/listPanel-2/listPanel";
import PanelPart from "../../../components/mainComponents/mainPartPage/panelParts/panelPart";
import TeacherPanel from "../TeacherPanel";
import SlidingContent from "../../../components/elementComponents/slidingContents/slidingContent/slidingContent";
import { Navigate, useParams } from "react-router-dom";
import API from "../../../api";
import { useEffect, useState } from "react";
import Part from "../../../components/mainComponents/mainPartPage/part";

const TeacherStudentShow = (props) => {

    var params=useParams();
    var api=new API();

    const [data,setData] = useState([]);
    const [navigate,setNavigate] = useState(false);

    useEffect(()=>{
        api.send(
            props.addMessage,
            'get',
            'teacher/student/show/'+params.id,
            handleApiInformation,
            {},
            ()=>{setNavigate(true)}
        );
    },[]);

    return (  
        <>
            {navigate==true ? <Navigate to='/panel/teacher/student'/> : ''}
            <TeacherPanel active='student'>
                <PanelPart title=' نمایش دانشجو ' description='شما می توانید دانشجو مورد نظر خود را در این قسمت به همراه اطلاعات دوره هایی که ثبت نام کرده و شما صاحب آن هستیدد با خبر شوید این دانش آموز حداقل در یکی از دوره های شما حضور داشته و لیست دوره هایی از شما که دانش آموز در آن ثبت نام کرده را ی بینید'>
                    <br />
                    <div className="default-line-items default-color-site">
                        <h1>اطلاعات</h1>
                        <p>
                            در این قسمت می توانید اطلاعات اولیه دانشجویی که قصد بازدید از او را دارید مشاهده کنید
                        </p>
                    </div>
                    <ListPanel2
                    items={[
                        ['آیدی',e=>e.id+'#'],
                        ['نوع',e=>e.type],
                        ['نام کاربری',e=>e.username],
                    ]}
                    data={[{
                        'username':data.username,
                        'id':data.id,
                        'type':data.type,
                    }]}
                    />
                    <br />
                    <div className="default-line-items default-color-site">
                        <h1>دوره ها</h1>
                        <p>
                            در این قسمت می توانید دوره هایی را که دانشجو در ان ثبت نام کرده و مدرس آنها شمایید مشاهده کنید
                        </p>
                    </div>
                    <ListPanel2
                    items={[
                        ['آیدی',e=>e.id+'#'],
                        ['عنوان',e=>e.title],
                        ['توضیحات',e=>e.description],
                        ['نوع',e=>e.type],
                        ['وضعیت',e=>e.status]
                    ]}
                    data={data.yourPeriods??[]}
                    />
                    <br />
                    <div className="default-line-items default-color-site">
                        <h1>تکالیف</h1>
                        <p>
                            در این قسمت تکالیف کاربر را می بینید 
                        </p>
                    </div>
                    <ListPanel2
                    items={[
                        ['آیدی',e=>e.id],
                        ['عنوان',e=>e.title],
                        ['توضیحات',e=>e.description],
                        ['وضعیت انجام',e=>e.pivot.doing_status],
                        ['دوره',e=>e.period.title]]}
                    data={data.yourDrills??[]}
                    />
                </PanelPart>
            </TeacherPanel>
        </>
    );

    function handleApiInformation(data){
        setData(data.info);
    }
}
 
export default TeacherStudentShow;